import { useState } from 'react';
import { useAmplifyAuth } from 'providers/AmplifyAuthProvider';
import { useHistory } from 'react-router-dom';

export const useCimRequestResetPassword = () => {
  const { forgotPassword } = useAmplifyAuth();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  return {
    loading,
    errorMessage,
    onRequestResetPassword: async email => {
      try {
        setLoading(true);
        await forgotPassword(email);
        setLoading(false);
        history.push('/reset-password', { email });
      } catch (error) {
        process.env.NODE_ENV !== 'production' && console.error(error.code);
        setLoading(false);
        setErrorMessage(error.message);
      }
    },
  };
};
