import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GhostButton } from 'design-system-components/Button';
import { ArrowWrap, Container } from './_style';

/**
 *  {
 *  next: {
 *   onClick: () => void,
 *   disabled: boolean,
 *  },
 *  prev: {
 *   onClick: () => void,
 *   disabled: boolean,
 *  }
 * }
 */
export const TableListWithArrow = ({ next, prev, children }) => {
  return (
    <Container>
      {children}
      <ArrowWrap>
        <GhostButton
          {...prev}
          data-testid="prev-btn"
          icon={<FontAwesomeIcon icon={['far', 'chevron-left']} />}
        />
        <GhostButton
          {...next}
          data-testid="next-btn"
          icon={<FontAwesomeIcon icon={['far', 'chevron-right']} />}
        />
      </ArrowWrap>
    </Container>
  );
};
