import 'antd/dist/antd.css';
import './style/style.css';

import 'react-toastify/dist/ReactToastify.css';
import './style/react-toastify.css';

import React from 'react';
import ReactDOM from 'react-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';

// debugging tool
import { init } from '@sentry/browser';

// tracking & analytics
import { activateHotjar, activateGTM } from 'utils/trackingAndAnalytics';

// styling
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import * as theme from 'style/theme';
import { GlobalStyle } from 'style/global-styles';

// routing
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

// main components
// import AccountProvider from 'providers/AccountProvider';
import CimRoleProvider from 'providers/CimRoleProvider';
import AmplifyAuthProvider from 'providers/AmplifyAuthProvider';
import LayoutProvider from 'providers/LayoutProvider';
import ReactQueryProvider from 'providers/ReactQueryProvider';
// import RegionProvider from 'providers/RegionProvider';
import MainLayout from 'layouts/Main';
import Router from 'router';

// diana-bot
import { DianaProvider } from '@amityco/diana-bot';

// i18n
import I18nProvider from './i18n';

// fontawesome
library.add(fas, far, fal);

// tracking & analytics
activateHotjar();
activateGTM();

// debugging
if (process.env.NODE_ENV === 'production') {
  init({ dsn: process.env.REACT_APP_SENTRY_ENDPOINT });
}

// eslint-disable-next-line no-underscore-dangle
window.__buildNumber__ = process.env.REACT_APP_COMMIT_SHA ?? '';

// necessary for dev environment
const BASE_URL = process.env.PUBLIC_URL ?? '/';

/*
  Order matters, please dont switch things.
  - QueryParamProvider needs to be inside a Router to read query params
  - AuthProvider needs to be inside a Router to allow redirection to /login if not authed
  - LayoutProvider needs to be inside AuthProvider since parts of the layout are accessing auth related data (header)
  - ModalPortal should be inside AuthProvider since some of the modals need access to the user
*/
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <StyleSheetManager disableVendorPrefixes={process.env.NODE_ENV !== 'production'}>
        {/* <RegionProvider> */}
        <DianaProvider>
          <I18nProvider locale="en">
            <BrowserRouter basename={BASE_URL}>
              <MainLayout>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <ReactQueryProvider>
                    <AmplifyAuthProvider>
                      <CimRoleProvider>
                        {/* <AccountProvider> */}
                        <LayoutProvider>
                          <Router />
                        </LayoutProvider>
                        {/* </AccountProvider> */}
                      </CimRoleProvider>
                    </AmplifyAuthProvider>
                  </ReactQueryProvider>
                </QueryParamProvider>
              </MainLayout>
            </BrowserRouter>
            <GlobalStyle />
          </I18nProvider>
        </DianaProvider>
        {/* </RegionProvider> */}
      </StyleSheetManager>
    </ThemeProvider>
  );
};

// kickstart
const render = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

render();
