import { rgba } from 'polished';
import styled from 'styled-components';

export const OrganizationContainer = styled.div`
  background: ${({ theme }) => rgba(theme.GREY_3, 0.5)};
  border-radius: 0.5rem;
  padding: 0.75rem;
  display: flex;
  align-items: center;

  > :first-child {
    flex: 0 0 auto;
  }

  > :not(:first-child) {
    margin-left: 0.75rem;
  }

  ${({ $squeezed }) =>
    $squeezed &&
    `
    padding: 0.75rem 0;
    background: none;
  `}
`;

export const OrganizationName = styled.div`
  color: ${({ theme }) => theme.WHITE};
  ${({ theme }) => theme.BODY2_14_MEDIUM}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OrganizationID = styled.div`
  color: ${({ theme }) => theme.GREY_6};
  ${({ theme }) => theme.CAPTION_12_REGULAR}
  word-break: break-all;
`;

export const OrganizationLabel = styled.div`
  color: ${({ theme }) => theme.GREY_6};
  ${({ theme }) => theme.CAPTION_12_REGULAR}
`;

export const NameLabelContainer = styled.div`
  overflow: hidden;
`;
