import styled from 'styled-components';

export const Font12500G5 = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #9e9e9e;
  margin: 0;
`;

export const Font12400G5 = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #9e9e9e;
  margin: 0;
`;

export const Font12600G1 = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: #616161;
  margin: 0;
`;

export const Font14400G1 = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #212121;
  margin: 0;
`;

export const Font14400G5 = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #9e9e9e;
  margin: 0;
`;

export const Font14600G1 = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #616161;
  margin: 0;
`;

export const Font16400G1 = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #212121;
  margin: 0;
`;

export const Font16500G3 = styled.span`
  color: #616161;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

export const Font16600G1 = styled.span`
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

export const Font20600G5 = styled.span`
  color: #9e9e9e;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

export const Font32600G1 = styled.span`
  font-size: 32px;
  font-weight: 600;
  color: #212121;
`;

export const RedNote = styled.span`
  color: red;
  display: inline !important;
`;
