import React, { useMemo } from 'react';
import { SideMenu } from '@amityco/diana-bot';
import styled from 'styled-components';
import { useCimRole } from 'providers/CimRoleProvider';
import { useAmplifyAuth } from 'providers/AmplifyAuthProvider';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { USER_PROFILE_PATH, SIDE_BAR_MEMBER, SIDE_BAR_PRODUCT } from 'constants/cim_routes';

export const Layout = styled.div`
  --gap: 1.5rem;
  --halfgap: calc(var(--gap) * 0.5);

  display: grid;
  grid-template-areas: 'side main';
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.WHITE};
  transition: 0.3s;

  ${({ theme }) => theme.BREAKPOINT_MOBILE} {
    --gap: 2rem;
  }
`;

const Main = styled.section`
  grid-area: main;
  overflow: auto;
  background: ${({ theme }) => theme.WHITE};
  ${({ theme }) => theme.BREAKPOINT_MOBILE} {
    --gap: 2rem;
  }
`;

export default ({ children }) => {
  const { role } = useCimRole();
  const { user, logout } = useAmplifyAuth();
  const history = useHistory();

  const generateSideMenu = useMemo(() => {
    const menu = [];
    if (isEmpty(role)) return menu;
    if (!isEmpty(role.cim?.members)) {
      menu.push(SIDE_BAR_MEMBER);
    }

    menu.push(SIDE_BAR_PRODUCT);

    return menu;
  }, [role]);

  return (
    <Layout>
      <Main>{children}</Main>
      <SideMenu
        member={{
          username: user?.attributes?.name ?? '',
          email: user?.attributes?.email ?? '',
        }}
        sideBarData={generateSideMenu}
        showSubMenu={false}
        onLogout={async () => {
          await logout();
        }}
        onChangeMemberStatus={() => undefined}
        onClickMenuItem={to => history.push(to)}
        onClickUserProfile={() => history.push(USER_PROFILE_PATH)}
      />
    </Layout>
  );
};
