import React from 'react';

import { Spin } from 'antd';
import styled from 'styled-components';

import LoadingCircle from 'design-system-components/Loading';

const AntdFullScreenSpinner = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;

  > .ant-spin-dot {
    color: ${({ theme }) => theme.COMPANY_COLOR};
    height: 5rem;
    width: 5rem;

    > svg {
      height: 100%;
      width: 100%;
    }
  }
`;

const FullScreenSpinner = ({ indicator = <LoadingCircle />, size = 'large', ...props }) => (
  <AntdFullScreenSpinner indicator={indicator} size={size} {...props} />
);

export default FullScreenSpinner;
