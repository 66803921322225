import { useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { getMember, updateMember, deleteMember } from 'api/member';
import message from 'components/message';
import { useTranslation } from 'hooks/useI18n';
import { MEMBER_EDITOR_WAIT_SYNC_TIME } from 'constants/cim_member_editor';

export const INITIAL_FORM_VALUES = {
  chatbotRole: undefined,
  chatbotCheckBox: false,
  livechatRole: undefined,
  livechatCheckBox: false,
  campaignRole: undefined,
  campaignCheckBox: false,
};

const getRoleFromDataArr = (roleArr, keyword) => {
  const index = roleArr.findIndex(role => role.search(keyword) !== -1);
  if (index === -1) {
    return undefined;
  }
  return roleArr[index];
};

export const useCimMemberEditor = () => {
  const history = useHistory();
  const t = useTranslation();
  const { id: memberId } = useParams();
  const [isEditMode, setIsEditMode] = useState(false);
  /** formData
   * @param {boolean} campaignCheckBox
   * @param {string} campaignRole
   * @param {boolean} chatbotCheckBox
   * @param {string} chatbotRole
   * @param {boolean} livechatCheckBox
   * @param {string} livechatRole
   */
  const tempFormData = useRef(INITIAL_FORM_VALUES);
  const tempSelectedMemberId = useRef(null);
  const [formData, setFormData] = useState(INITIAL_FORM_VALUES);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const { isFetching: memberInfoLoading, data: memberInfo } = useQuery(
    ['get-member-profile', memberId],
    () => getMember(memberId),
    {
      onSuccess: d => {
        const { data } = d;
        const getChatbotRole = getRoleFromDataArr(data.roles, 'chatbot');
        const getLivechatRole = getRoleFromDataArr(data.roles, 'livechat');
        const getCampaignRole = getRoleFromDataArr(data.roles, 'campaign');

        tempFormData.current = {
          chatbotRole: getChatbotRole,
          chatbotCheckBox: !!getChatbotRole,
          livechatRole: getLivechatRole,
          livechatCheckBox: !!getLivechatRole,
          campaignRole: getCampaignRole,
          campaignCheckBox: !!getCampaignRole,
        };
        setFormData(() => {
          return {
            chatbotRole: getChatbotRole,
            chatbotCheckBox: !!getChatbotRole,
            livechatRole: getLivechatRole,
            livechatCheckBox: !!getLivechatRole,
            campaignRole: getCampaignRole,
            campaignCheckBox: !!getCampaignRole,
          };
        });
      },
      onError: () => {
        history.replace('/member');
      },
    },
  );

  const mutationUpdateMember = useMutation(({ id, data }) => updateMember(id, data), {
    onSuccess: () => {
      setTimeout(() => {
        message.success(t('cim.member.editor.page.update.success'));
        history.goBack();
      }, MEMBER_EDITOR_WAIT_SYNC_TIME);
    },
    onError: () => {
      setIsUpdating(false);
    },
  });

  const mutationDeleteMember = useMutation(
    id => {
      return deleteMember(id);
    },
    {
      onSuccess: () => {
        setTimeout(() => {
          message.success(t('cim.member.editor.page.delete.success'));
          history.goBack();
        }, MEMBER_EDITOR_WAIT_SYNC_TIME);
      },
      onError: () => {
        setIsDeleting(false);
      },
    },
  );

  return {
    isEditMode,
    formData,
    memberInfoLoading,
    memberInfo: memberInfo?.data,
    mutationUpdateMember,
    isDeleteModalOpen,
    isUpdating,
    isDeleting,
    isDisableCheckbox: (role, productName) => {
      return !isEditMode || !role?.subscribedProducts.find(product => product === productName);
    },
    isDisableSelect: (role, productName) => {
      return (
        !isEditMode ||
        !formData?.chatbotCheckBox ||
        !role?.subscribedProducts.find(product => product === productName)
      );
    },
    onDeleteMember: () => {
      tempSelectedMemberId.current = memberId;
      setDeleteModalOpen(true);
    },
    onConfirmDelete: () => {
      if (tempSelectedMemberId.current) {
        setIsDeleting(true);
        mutationDeleteMember.mutate(tempSelectedMemberId.current);
      }
    },
    onCancelDelete: () => {
      if (!isDeleting) {
        setDeleteModalOpen(false);
        tempSelectedMemberId.current = '';
      }
    },
    onFormChange: changedFields => {
      if (changedFields.length === 0) {
        return;
      }
      setFormData(prev => {
        return {
          ...prev,
          [changedFields[0].name]: changedFields[0].value,
        };
      });
    },
    onSwitchEditMode: () => {
      if (isEditMode) {
        setFormData(tempFormData.current);
      }
      setIsEditMode(!isEditMode);
    },
    onUpdate: () => {
      setIsUpdating(true);
      const newMemberInfo = {
        name: memberInfo.data.name,
        email: memberInfo.data.email,
        roles: [
          getRoleFromDataArr(memberInfo.data.roles, 'cim'),
          formData.chatbotRole ? formData.chatbotRole : undefined,
          formData.campaignRole ? formData.campaignRole : undefined,
        ].filter(Boolean),
      };

      mutationUpdateMember.mutate({ id: memberInfo.data.id, data: newMemberInfo });
      return newMemberInfo;
    },
    onChatbotCheckBox: (checkBoxName, roleSelectName, data) => {
      setFormData(prev => {
        return {
          ...prev,
          [checkBoxName]: data.target.checked,
          [roleSelectName]: data.target.checked ? prev[roleSelectName] : undefined,
        };
      });
    },
    onRoleChange: (product, data) => {
      setFormData(prev => {
        return {
          ...prev,
          [product]: data,
        };
      });
    },
  };
};
