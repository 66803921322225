export const CHATBOT_ROLES = [
  { label: 'Admin', value: 'chatbot.admin' },
  { label: 'Developer', value: 'chatbot.developer' },
  { label: 'Editor', value: 'chatbot.editor' },
  { label: 'Moderator', value: 'chatbot.moderator' },
];

export const ROLES_MAPPING = {
  CIM_ADMIN: 'cim.admin',
};

export const MEMBER_EDITOR_WAIT_SYNC_TIME = 3000;

export const FORM_DATA_MAPPING = {
  CHATBOT_ROLE: 'chatbotRole',
  CHATBOT_CHECKBOX: 'chatbotCheckBox',
  CAMPAIGN_ROLE: 'campaignRole',
  CAMPAIGN_CHECKBOX: 'campaignCheckBox',
};

export const CAMPAIGN_ROLES = [
  { label: 'Admin', value: 'campaign.admin' },
  { label: 'Editor', value: 'campaign.editor' },
  { label: 'Moderator', value: 'campaign.moderator' },
];

export const ALL_ROLES = {
  chatbot: CHATBOT_ROLES,
  campaign: CAMPAIGN_ROLES,
};
