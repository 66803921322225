import React from 'react';
import { TableListWithArrow } from 'components/TableListWithArrow';
import { Spin, Typography } from 'antd';
import { Font16400G1, Font16500G3 } from 'components/UtilitiesComponent';
import { CIM_ROLE_FILTER } from 'constants/cim_role_filter';
import { useTranslation } from 'hooks/useI18n';
import { DeleteModal, LoadingCircle, LoadingWrap } from '@amityco/diana-bot';
import { useCimRole } from 'providers/CimRoleProvider';
import { FormFilter } from './FormFilter';
import { TableList } from './TableList';
import { useCimMemberList } from './useCimMemberList';
import { Container, Wrap } from './_style';

const CimMemberList = () => {
  const t = useTranslation();
  const { role } = useCimRole();
  const {
    isDeleteModalOpen,
    tempSelectedMemberId,
    memberList,
    isFetching,
    tempCursorId,
    isDeleting,
    onSearch,
    onDeleteMember,
    onConfirmDelete,
    onCancelDelete,
  } = useCimMemberList();

  return (
    <Container>
      <Typography.Title level={2}>{t('cim.member.list.page.title')}</Typography.Title>
      <Font16500G3>{t('cim.member.list.page.description')}</Font16500G3>
      <Wrap>
        <FormFilter
          options={CIM_ROLE_FILTER.filter(item =>
            role.subscribedProducts.includes(item.group.toLowerCase()),
          )}
          onSearch={onSearch}
        />
        <Spin
          spinning={isFetching}
          indicator={
            <LoadingWrap>
              <LoadingCircle />
            </LoadingWrap>
          }
        >
          <TableListWithArrow
            prev={{
              disabled: tempCursorId.currentPage === 1,
              onClick: () => onSearch({ prev: tempCursorId.prevCursorId }),
            }}
            next={{
              disabled: !tempCursorId.nextCursorId,
              onClick: () => onSearch({ next: tempCursorId.nextCursorId }),
            }}
          >
            <TableList data={memberList} onDeleteMember={onDeleteMember} />
          </TableListWithArrow>
        </Spin>
      </Wrap>
      <DeleteModal
        destroyOnClose
        visible={isDeleteModalOpen}
        maskClosable={false}
        confirmBtnTxt={t('cim.member.list.page.modal.confirm.delete.btn')}
        cancelBtnTxt={t('cim.member.list.page.modal.cancel.delete.btn')}
        handleConfirm={onConfirmDelete}
        handleClose={onCancelDelete}
        isLoading={isDeleting}
        centered
      >
        <Font16400G1>{t('cim.member.list.page.modal.delete.label')}</Font16400G1>
        <Font16400G1>
          {t('cim.member.list.page.modal.delete.description', {
            name: memberList?.find(item => item.id === tempSelectedMemberId)?.name ?? '',
          })}
        </Font16400G1>
      </DeleteModal>
    </Container>
  );
};

export default CimMemberList;
