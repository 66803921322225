import { client } from '.';

export const getMembers = ({ keyword, cursorId, skip, take = 10, roles }) => {
  return client.get('/api/v1/members', {
    params: {
      keyword,
      cursorId,
      skip,
      take,
      roles,
    },
  });
};

/**
 * @param {string} id member id
 * @param {Object} data contain name, phone, roles
 * @param {string} data.networkId
 * @param {string} data.name username
 * @param {string} data.phone phone number
 * @param {Array} data.roles [cim.admin, chatbot.admin]
 */
export const updateMember = (id, data) => {
  return client.put(`/api/v1/members/${id}`, data);
};

export const getMember = id => {
  return client.get(`/api/v1/members/${id}`);
};

export const deleteMember = memberId => {
  return client.delete(`/api/v1/members/${memberId}`);
};
