import { Auth } from 'aws-amplify';

const addToken = (config, token) => {
  const newConfig = { ...config };
  newConfig.headers.Authorization = `Bearer ${token}`;
  return newConfig;
};

export const checkAuthInterceptor = async config => {
  try {
    const session = await Auth.currentSession();

    const idTokenExpire = session.getIdToken().getExpiration();
    const refreshToken = session.getRefreshToken();
    const currentTimeSeconds = Math.round(+new Date() / 1000);
    if (idTokenExpire >= currentTimeSeconds) {
      return addToken(config, session.idToken.jwtToken);
    }

    const res = await Auth.currentAuthenticatedUser();

    /*
      here, since you use callbacks, i wrapped it into a promise, but if there is a promise mode, it would be more clean to use it.
    */
    return new Promise((resolve, reject) => {
      res.refreshSession(refreshToken, (err, data) => {
        if (err) {
          Auth.signOut();
          reject(err); // we pass the error upstairs
        } else {
          resolve(addToken(config, data.accessToken.jwtToken));
        }
      });
    });
  } catch (error) {
    process.env.NODE_ENV !== 'production' && console.error(error);
    return config;
  }
};
