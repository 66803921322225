import React from 'react';
import { Font16400G1 } from 'components/UtilitiesComponent';
// eslint-disable-next-line import/no-unresolved
import { Checkbox, FormItem, Select } from '@amityco/diana-bot';

export const ProductPermissionFormItem = ({
  selectPlaceholder = 'Select role',
  productName = 'Chatbot',
  disabledCheckbox = true,
  disabledSelect = true,
  onRoleChange,
  onCheckboxChange,
  roles,
  isCheck,
  selectedRole,
  formSelectName,
}) => {
  return (
    <React.Fragment>
      <FormItem valuePropName="checked">
        <Checkbox
          checked={isCheck}
          disabled={disabledCheckbox}
          data-testid={`test-product-${formSelectName}-permission-checkbox`}
          onChange={onCheckboxChange}
        >
          <Font16400G1>{productName}</Font16400G1>
        </Checkbox>
      </FormItem>
      <FormItem>
        <Select
          size="large"
          data-testid={`test-product-${formSelectName}-permission-select`}
          options={roles}
          disabled={disabledSelect}
          placeholder={selectPlaceholder}
          value={selectedRole}
          onChange={onRoleChange}
        />
      </FormItem>
    </React.Fragment>
  );
};
