import React from 'react';
import { Container } from './_style';

export const Tag = ({ children, bgColor = '#212121', fontColor = '#FFFFFF' }) => {
  return (
    <Container bgColor={bgColor} fontColor={fontColor}>
      {children}
    </Container>
  );
};
