import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

export const LayoutContext = createContext({ setLayout: () => {} });

export const useLayout = () => useContext(LayoutContext);

export default ({ children } = {}) => {
  const [key, setKey] = useState(0);
  const layout = useRef();

  const onChange = useCallback(
    newVal => {
      /*
      a child route has asked for a change of layout.
      we can't cancel the current rendering cycle, so we return a boolean
      to indicate we have taken the request in account, for which the route
      should stop its render immediatly, and we schedule a new render for
      next tick.

      this is definitely not ideal but the only way to keep the layout instance
      while at the same time allow children of the layout to invalidate it.
    */
      if (newVal !== layout.current) {
        setTimeout(() => {
          layout.current = newVal;
          setKey(key + 1);
        }, 0);

        return true;
      }

      return false;
    },
    [key],
  );

  // for rendering
  const Layout = layout.current;

  const layoutContextValue = useMemo(() => ({ key, setLayout: onChange }), [key, onChange]);
  return (
    <LayoutContext.Provider value={layoutContextValue}>
      {Layout ? <Layout>{children}</Layout> : children}
    </LayoutContext.Provider>
  );
};
