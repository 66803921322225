import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';

import { Amplify, Hub } from 'aws-amplify';

import { client } from 'api';
import awsExports from 'config/aws-exports';

import { checkAuthInterceptor } from './checkForAuth';
import * as fns from './functions';

export const AmplifyAuthContext = React.createContext(null);
export const useAmplifyAuth = () => useContext(AmplifyAuthContext);

const AmplifyAuthProvider = ({ children }) => {
  const tempUser = useRef(null);
  const { formatMessage } = useIntl();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [authErrorMessage, setAuthErrorMessage] = useState({
    message: '',
    status: '',
  });

  // this error will pass to the page that use use authErrorMessage
  const errorFormatter = useCallback(
    message => {
      if (message.toLowerCase().includes('postconfirmation+failed')) {
        return setAuthErrorMessage({
          message: formatMessage({ id: 'amplify.auth.error.post.confirmation' }),
          status: 'fail',
        });
      }
      if (message.toLowerCase().includes('personal+email+is+not+allowed')) {
        return setAuthErrorMessage({
          message: formatMessage({ id: 'amplify.auth.error.personal.not.allow' }),
          status: 'fail',
        });
      }
    },
    [formatMessage],
  );

  /*
    this part was inside the Hub listener for cases 'signIn' and 'cognitoHostedUI' and also inside the useEffect hook directly. Make it one function is more consistant. I refactored the code to use async/await for readability.
  */
  const tryLogin = useCallback(async () => {
    try {
      setIsLoading(true);
      const userData = await fns.getUser();
      setUser(userData);
      setIsLoading(false);
    } catch (error) {
      process.env.NODE_ENV !== 'production' && console.error(error);
      setUser(null);
      setIsLoading(false);
    }
  }, []);

  /*
    this is the listener function you had put inside the Hub.listen('auth') call. I extracted it into a function, joined the cases for failure and used the tryLogin() function directly inside
  */
  const hubListener = useCallback(
    async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          await tryLogin();
          break;
        case 'signIn_failure':
          errorFormatter(data.message);
          break;
        case 'cognitoHostedUI_failure':
        case 'signOut':
        default:
          setUser(null);
          break;
      }
    },
    [errorFormatter, tryLogin],
  );

  /*
    The Hub.listen uses the hubListener function ; we reuse the tryLogin function from before ; and we have return statement in the useEffect which dispose the hubListener and the interceptor correctly.
  */
  useEffect(() => {
    Amplify.configure(awsExports);

    const interceptor = client.interceptors.request.use(checkAuthInterceptor);
    Hub.listen('auth', hubListener);
    tryLogin();

    return () => {
      client.interceptors.request.eject(interceptor);
      Hub.remove('auth', hubListener);
    };
  }, [hubListener, tryLogin]);

  const values = React.useMemo(
    () => ({
      ...fns,
      user,
      tempUser,
      authErrorMessage,
      isLoading,
      setUser,
      onRequestForgetPassword: async email => {
        try {
          return await fns.forgotPassword(email);
        } catch (error) {
          setAuthErrorMessage({
            message: error.message ?? error,
            status: 'fail',
          });
        }
      },
      setAuthErrorMessage,
    }),
    [user, isLoading, authErrorMessage],
  );

  return <AmplifyAuthContext.Provider value={values}>{children}</AmplifyAuthContext.Provider>;
};

export default AmplifyAuthProvider;
