import React from 'react';

import Icon, { WarningFilled } from '@ant-design/icons';
import styled from 'styled-components';

import { failToast } from './styles';

const Container = styled.div`
  ${failToast};
`;

const DangerToast = ({ children, align }) => {
  return (
    <Container align={align}>
      <Icon component={WarningFilled} />
      {children}
    </Container>
  );
};

export default DangerToast;
