import * as theme from 'style/theme';

export const MIDDLE_BUTTON = `
  ${theme.BODY2_14_SEMIBOLD}
  height: 2.25rem;

  &.ant-btn-icon-only {
    width: 2.25rem;
  }
`;

export const LARGE_BUTTON = `
  ${theme.BODY1_16_SEMIBOLD}
  height: 3rem;

  &.ant-btn-icon-only {
    width: 3rem;
  }
`;

export const DEFAULT_BUTTON = `
  border: none !important;
  border-radius: 0.5rem;
  box-shadow: none;
  background: ${theme.GREY_9};
  color: ${theme.GREY_1};

  &:hover:not(:disabled) {
    background: ${theme.GREY_8};
    color: ${theme.GREY_1}
  }

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background: ${theme.GREY_7};
    color: ${theme.GREY_1}
  }

  &:disabled, &:disabled:hover {
    color: ${theme.GREY_4};
  }
`;

export const PRIMARY_BUTTON = `
  border: none !important;
  border-radius: 0.5rem;
  box-shadow: none;
  background: ${theme.COMPANY_COLOR};
  color: ${theme.WHITE};

  &:hover:not(:disabled) {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), ${theme.COMPANY_COLOR};
    color: ${theme.WHITE};
  }

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), ${theme.COMPANY_COLOR};
    color: ${theme.WHITE};
  }

  &&&:disabled {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),  ${theme.COMPANY_COLOR};
    color: ${theme.WHITE};
  }
`;

export const DANGER_BUTTON = `
  border: none !important;
  border-radius: 0.5rem;
  box-shadow: none;
  background: ${theme.RED};
  color: ${theme.WHITE};

  &:hover:not(:disabled) {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #F00630;
    color: ${theme.WHITE};
  }

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #F00630;
    color: ${theme.WHITE};
  }

  &&&:disabled {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #F00630;
    color: ${theme.WHITE};
  }
`;

export const GHOST_BUTTON = `
  border: none !important;
  border-radius: 0.5rem;
  box-shadow: none;
  background: none;
  color: ${theme.GREY_1};

  &:hover:not(:disabled) {
    background: none;
    color: ${theme.GREY_2};
  }

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background: ${theme.GREY_9};
    color: ${theme.GREY_1};
  }

  &&&:disabled {
    background: none;
    color: ${theme.GREY_5};
  }
`;

export const TERTIARY_BUTTON = `
  border: 1px solid ${theme.GREY_6};
  border-radius: 0.5rem;
  box-shadow: none;
  color: ${theme.GREY_1};
  background: ${theme.WHITE};

  &:hover:not(:disabled) {
    border-color: ${theme.GREY_5};
    background: ${theme.WHITE};
    color: ${theme.GREY_1};
  }

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    border-color: ${theme.GREY_9};
    background: ${theme.GREY_9};
    color: ${theme.GREY_1};
  }

  &&&:disabled {
    border-color: ${theme.GREY_7};
    background: ${theme.WHITE};
    color: ${theme.GREY_5};
  }
`;

export const SECONDARY_BUTTON = `
  border: none !important;
  border-radius: 0.5rem;
  box-shadow: none;
  color: ${theme.GREY_1} !important;
  background: rgba(0, 0, 0, 0.07);

  &:hover:not(:disabled) {
    background: rgba(0, 0, 0, 0.12);    
  }

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background: rgba(0, 0, 0, 0.18);
  }

  &&&:disabled {
    color: rgba(0, 0, 0, 0.18);
    background: rgba(0, 0, 0, 0.04);
  }
`;
