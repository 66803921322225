import styled from 'styled-components';

export const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 40px 24px;
  height: 100%;
`;

export const ProductsContainer = styled.div`
  margin-top: 24px;
`;
