/*eslint-disable */
export default {
  aws_project_region: process.env.REACT_APP_COGNITO_REGION,
  authenticationFlowType: process.env.REACT_APP_COGNITO_AUTH_FLOW_TYPE,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
    scope: process.env.REACT_APP_COGNITO_OAUTH_SCOPE?.split(',') ?? [],
    redirectSignIn: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_OUT,
    responseType: process.env.REACT_APP_COGNITO_OAUTH_RESPONSETYPE,
  },
  Auth: {
    region: process.env.REACT_APP_COGNITO_AUTH_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_AUTH_USER_POOL_CLIENT_ID,
    cookieStorage: {
      domain: process.env.REACT_APP_COGNITO_AUTH_COOKIE_DOMAIN,
      path: process.env.REACT_APP_COGNITO_AUTH_COOKIE_PATH,
      expires: parseFloat(process.env.REACT_APP_COGNITO_AUTH_COOKIE_EXPIRES),
      secure: process.env.REACT_APP_COGNITO_AUTH_COOKIE_SECURE ? true : false,
    },
  },
};
