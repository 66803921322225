import React from 'react';
import { Spin } from 'antd';
import { LoadingCircle, LoadingWrap } from '@amityco/diana-bot';
import { UserInfoContent } from './UserInfoContent';
import { useCimUserProfile } from './useCimUserProfile';
import { Container } from './_style';

const CimUserProfile = () => {
  const {
    isEditMode,
    isUpdating,
    isUserInfoLoading,
    userInfo,
    onClickResetPassword,
    onSwitchEditMode,
    onCancelEdit,
    productAccessRole,
    form,
    onUpdate,
  } = useCimUserProfile();

  return (
    <Container>
      <Spin
        spinning={isUserInfoLoading || isUpdating}
        indicator={
          <LoadingWrap>
            <LoadingCircle />
          </LoadingWrap>
        }
      >
        <UserInfoContent
          isEditMode={isEditMode}
          userInfo={userInfo}
          form={form}
          productAccessRole={productAccessRole}
          onSwitchEditMode={onSwitchEditMode}
          onCancelEdit={onCancelEdit}
          onClickResetPassword={onClickResetPassword}
          onUpdate={onUpdate}
        />
      </Spin>
    </Container>
  );
};

export default CimUserProfile;
