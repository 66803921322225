import React from 'react';
import { Route } from 'react-router-dom';

import { useLayout } from 'providers/LayoutProvider';

export default ({ meta = {}, ...props }) => {
  const { setLayout } = useLayout();
  if (setLayout(meta.layout)) return null;

  return <Route {...props} exact />;
};
