import React from 'react';
import { Form, Typography } from 'antd';
import AmityLog from 'assets/images/amity_logo.svg';
import { Font16500G3, Font12500G5 } from 'components/UtilitiesComponent';
import FormItem from 'design-system-components/FormItem';
import { Button, PrimaryButton, TextField, PasswordInput } from 'design-system-components';
import { DangerToast } from 'design-system-components/Toast';
import { useHistory } from 'react-router';
import { LoadingIcon } from 'components/LoadingIcon';
import { useTranslation } from 'hooks/useI18n';
import { Container, FormInnerWrap } from './_style';
import { useCimResetPassword } from './useCimResetPassword';

const ResetPassword = () => {
  const t = useTranslation();
  const history = useHistory();
  const { loading, errorMessage, onResetPassword } = useCimResetPassword();
  return (
    <Container>
      <FormInnerWrap>
        <img src={AmityLog} width={138} height={40} alt="" />
        <Typography.Title level={4}>{t('cim.reset.password.page.title')}</Typography.Title>
        {errorMessage && <DangerToast align="flex-start">{errorMessage}</DangerToast>}

        <Form layout="vertical" onFinish={({ code, password }) => onResetPassword(code, password)}>
          <FormItem
            name="code"
            rules={[{ required: true, message: t('cim.reset.password.page.code.required') }]}
            label={<Font16500G3>{t('cim.reset.password.page.code.label')}</Font16500G3>}
          >
            <TextField type="text" placeholder={t('cim.reset.password.page.code.placeholder')} />
          </FormItem>
          <FormItem
            name="password"
            rules={[{ required: true, message: t('cim.reset.password.page.password.required') }]}
            label={<Font16500G3>{t('cim.reset.password.page.password.label')}</Font16500G3>}
            help={<Font12500G5>{t('cim.reset.password.page.password.hint')}</Font12500G5>}
          >
            <PasswordInput
              type="password"
              placeholder={t('cim.reset.password.page.password.placeholder')}
            />
          </FormItem>
          <PrimaryButton htmlType="submit" size="large" disabled={loading}>
            {loading && <LoadingIcon icon={['fas', 'sync-alt']} />}
            {t('cim.reset.password.page.confirm.btn')}
          </PrimaryButton>
          <Button size="large" onClick={() => history.goBack()}>
            {t('cim.reset.password.page.goback.btn')}
          </Button>
        </Form>
      </FormInnerWrap>
    </Container>
  );
};

export default ResetPassword;
