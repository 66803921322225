import React from 'react';
import ReactAvatar from 'react-avatar';

import { AvatarContainer } from './styles';
import { getAlphabetAvatarPreset } from './utils';

const Avatar = ({ className, imgUrl, name, onClick, size = 56, style }) => {
  if (imgUrl) {
    return (
      <AvatarContainer
        backgroundImage={imgUrl}
        className={className}
        size={size}
        style={style}
        onClick={onClick}
      />
    );
  }

  const { color, backgroundColor } = getAlphabetAvatarPreset(name);

  return (
    <AvatarContainer className={className} size={size} style={style} onClick={onClick}>
      <ReactAvatar
        className={className}
        color={backgroundColor}
        fgColor={color}
        maxInitials={2}
        name={name}
        round
        size={size}
      />
    </AvatarContainer>
  );
};

export default Avatar;
