import React from 'react';
import { Form } from 'antd';
import { FormItem, TextInput } from '@amityco/diana-bot';
import { useTranslation } from 'hooks/useI18n';

export const UserInfoFromItem = ({ isEditMode = false, form }) => {
  const t = useTranslation();
  return (
    <Form layout="vertical" form={form}>
      <FormItem name="name" label={t('userInfo.page.setting.profileName')}>
        <TextInput size="large" disabled={!isEditMode} />
      </FormItem>
      <FormItem name="phone" label={t('userInfo.page.setting.phoneNumber')}>
        <TextInput size="large" disabled={!isEditMode} />
      </FormItem>
    </Form>
  );
};
