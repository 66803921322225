import React from 'react';
import {
  Font12600G1,
  Font14400G1,
  Font14400G5,
  Font14600G1,
  Font12400G5,
} from 'components/UtilitiesComponent';
import { Avatar, DropdownGroup } from '@amityco/diana-bot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GhostButton } from 'design-system-components';
import { Tag } from 'components/Tag';
import { generateDate } from 'utils/generateDate';

import {
  CheckIcon,
  BlockIcon,
  ColumnHeaderItemWrap,
  NoCapital,
  UserNameGroup,
  UserDetail,
  Capital,
} from './_style';

const ROLE_TITLE = {
  DEVELOPER: 'developer',
  EDITOR: 'editor',
  SUPERVISOR: 'supervisor',
  MODERATOR: 'moderator',
  AGENT: 'agent',
};

export const TEMPLATE_LIST_TABLE_ACTIONS = [
  {
    key: '',
    items: [
      {
        icon: {
          type: 'fas',
          value: ['fas', 'edit'],
        },
        label: 'Preview & edit',
        value: 'preview_edit',
      },
      {
        icon: {
          type: 'fas',
          value: ['fas', 'trash'],
        },
        label: 'Delete',
        value: 'delete',
      },
    ],
  },
];

const getTagBgColor = role => {
  if (role.includes(ROLE_TITLE.DEVELOPER)) {
    return '#FFF4D9';
  }
  if (role.includes(ROLE_TITLE.EDITOR)) {
    return '#EEEEFA';
  }
  if (role.includes(ROLE_TITLE.SUPERVISOR)) {
    return '#E6F8FA';
  }
  if (role.includes(ROLE_TITLE.MODERATOR)) {
    return '#FCE8F1';
  }
  if (role.includes(ROLE_TITLE.AGENT)) {
    return '#FFF1EA';
  }
  return '#212121';
};

const getFontColor = role => {
  if (role.includes(ROLE_TITLE.DEVELOPER)) {
    return '#FFB400';
  }
  if (role.includes(ROLE_TITLE.EDITOR)) {
    return '#5856D6';
  }
  if (role.includes(ROLE_TITLE.SUPERVISOR)) {
    return '#05BED2';
  }
  if (role.includes(ROLE_TITLE.MODERATOR)) {
    return '#E61E73';
  }
  if (role.includes(ROLE_TITLE.AGENT)) {
    return '#FF742F';
  }
  return 'white';
};

const renderTitleByProduct = (title, isPurchased) => {
  if (isPurchased) {
    return (
      <ColumnHeaderItemWrap>
        <CheckIcon icon={['fas', 'check-circle']} />
        <Font14400G1>
          <NoCapital>{title}</NoCapital>
        </Font14400G1>
      </ColumnHeaderItemWrap>
    );
  }
  return (
    <ColumnHeaderItemWrap>
      <BlockIcon icon={['fas', 'ban']} />
      <Font14400G5>
        <NoCapital>{title}</NoCapital>
      </Font14400G5>
    </ColumnHeaderItemWrap>
  );
};

const isPurchased = (products, productName) => {
  return products.find(product => product === productName);
};

export const TableColumnHeader = (history, isShowDeleteBtn, onDeleteMember, productAccess) => {
  let tableActionList = [];
  if (!isShowDeleteBtn) {
    tableActionList[0] = {
      key: '',
      items: [
        {
          icon: {
            type: 'fas',
            value: ['fas', 'edit'],
          },
          label: 'Preview & edit',
          value: 'preview_edit',
        },
      ],
    };
  } else {
    tableActionList = TEMPLATE_LIST_TABLE_ACTIONS;
  }

  return [
    {
      title: (
        <ColumnHeaderItemWrap>
          <Font12600G1>USERNAME</Font12600G1>
        </ColumnHeaderItemWrap>
      ),
      dataIndex: 'username',
      key: 'username',
      render: (_, { name, email }) => {
        return (
          <UserNameGroup>
            <Avatar height={32} width={32}>
              {name}
            </Avatar>
            <UserDetail>
              <Font14600G1>{name}</Font14600G1>
              <Font12400G5>{email}</Font12400G5>
            </UserDetail>
          </UserNameGroup>
        );
      },
    },
    {
      title: (
        <ColumnHeaderItemWrap>
          <Font12600G1>JOIN DATE</Font12600G1>
        </ColumnHeaderItemWrap>
      ),
      dataIndex: 'join_date',
      key: 'join_date',
      render: (_, { createdAt }) => {
        return <Font14400G1>{generateDate(createdAt)}</Font14400G1>;
      },
    },
    {
      title: renderTitleByProduct('Chatbot', isPurchased(productAccess, 'chatbot')),
      dataIndex: 'chatbot',
      key: 'chatbot',
      render: (_, record) => {
        if (!isPurchased(productAccess, 'chatbot')) return '-';
        const chatBotRole = record.roles.find(item => item.startsWith('chatbot'));
        if (chatBotRole) {
          return (
            <Tag bgColor={getTagBgColor(chatBotRole)} fontColor={getFontColor(chatBotRole)}>
              <Capital>{chatBotRole.split('.')[1]}</Capital>
            </Tag>
          );
        }
        return null;
      },
    },
    {
      title: renderTitleByProduct('Campaign', isPurchased(productAccess, 'campaign')),
      dataIndex: 'campaign',
      key: 'campaign',
      render: (_, record) => {
        if (!isPurchased(productAccess, 'campaign')) return '-';
        const campaignRole = record.roles.find(item => item.startsWith('campaign'));
        if (campaignRole) {
          return (
            <Tag bgColor={getTagBgColor(campaignRole)} fontColor={getFontColor(campaignRole)}>
              <Capital>{campaignRole.split('.')[1]}</Capital>
            </Tag>
          );
        }
        return null;
      },
    },
    {
      title: null,
      dataIndex: 'action',
      key: 'action',
      width: 150,
      render: (_, record) => {
        return (
          <DropdownGroup
            placement="bottomLeft"
            btn={setFunction => {
              return (
                <GhostButton
                  data-testid="test-member-table-action-btn"
                  onClick={() => setFunction(true)}
                >
                  <FontAwesomeIcon
                    color="#757575"
                    icon={['far', 'ellipsis-h']}
                    style={{ cursor: 'pointer' }}
                  />
                </GhostButton>
              );
            }}
            data={tableActionList}
            handleSelectItem={item => {
              if (item.value === 'preview_edit') {
                history.push(`member/${record.id}`);
              } else {
                onDeleteMember(record.id);
              }
            }}
          />
        );
      },
    },
  ];
};
