import { Auth } from 'aws-amplify';

import { exceptionHandler } from './utils';

const EXCEPTIONS = {
  InvalidPasswordException: {
    'password not long enough': 'signupForm.password.validation.length',
    'password must have lowercase characters': 'signupForm.password.validation.lower',
    'password must have uppercase characters': 'signupForm.password.validation.caps',
    'password must have numeric characters': 'signupForm.password.validation.numbers',
    'password must have symbol characters': 'signupForm.password.validation.special',
  },
};

const signUpWithEmail = ({ email, password }) => {
  if (!email || !password) throw new Error('Username or code is empty');
  const newEmail = email.trim();
  return Auth.signUp({
    username: newEmail,
    password,
    attributes: {
      email: newEmail,
    },
  }).catch(e => exceptionHandler(e, EXCEPTIONS));
};

export default signUpWithEmail;
