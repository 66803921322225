import { useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import { getMember, updateMember } from 'api/member';
import { useAmplifyAuth } from 'providers/AmplifyAuthProvider';
import { Form } from 'antd';
import { CHATBOT_ROLE_LABELS_MAPPING } from 'constants/cim_user_info';
import message from 'components/message';
import { useTranslation } from 'hooks/useI18n';
import { RESET_PASSWORD_PATH } from 'constants/cim_routes';

export const PRODUCT_ACCESS_VALUES = {
  livechatRole: undefined,
  chatbotRole: undefined,
  campaignRole: undefined,
};

const getRoleFromDataArr = (roles, keyword) => {
  const index = roles.findIndex(role => role.search(keyword) !== -1);
  if (index === -1) {
    return undefined;
  }
  return CHATBOT_ROLE_LABELS_MAPPING[roles[index]];
};

export const useCimUserProfile = () => {
  const history = useHistory();
  const { user } = useAmplifyAuth();
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [initalUserInfo, setInitalUserInfo] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const [productAccessRole, setProductAccessRole] = useState(PRODUCT_ACCESS_VALUES);
  const [userInfo, setUserInfo] = useState();
  const t = useTranslation();

  const { isFetching: isUserInfoLoading } = useQuery(
    'userInfo',
    () => getMember(user.attributes.sub),
    {
      onSuccess: ({ data }) => {
        setUserInfo({ ...data });

        const chatbotRole = getRoleFromDataArr(data.roles, 'chatbot');
        const livechatRole = getRoleFromDataArr(data.roles, 'livechat');
        const campaignRole = getRoleFromDataArr(data.roles, 'campaign');

        form.setFieldsValue(data);
        setInitalUserInfo(form.getFieldValue);
        setProductAccessRole(() => {
          return {
            chatbotRole,
            livechatRole,
            campaignRole,
          };
        });
      },

      onError: error => {
        console.error(error);
      },
    },
  );

  const onClickResetPassword = () => {
    history.push(RESET_PASSWORD_PATH);
  };

  const onSwitchEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const onCancelEdit = () => {
    form.setFieldsValue({ ...initalUserInfo });
    setIsEditMode(!isEditMode);
  };

  const mutationUpdateUserInfo = useMutation(({ id, data }) => updateMember(id, data), {
    onSuccess: result => {
      message.success(t('userInfo.page.update.success'));
      setIsUpdating(false);
      onSwitchEditMode();
      setInitalUserInfo({ ...result.data });
      setUserInfo({ ...result.data });
    },
    onError: () => {
      setIsUpdating(false);
    },
  });

  return {
    isEditMode,
    isUpdating,
    isUserInfoLoading,
    userInfo,
    form,
    initalUserInfo,
    productAccessRole,
    mutationUpdateUserInfo,
    setIsUpdating,
    setIsEditMode,
    onCancelEdit,
    onClickResetPassword,
    onSwitchEditMode,
    onUpdate: () => {
      setIsUpdating(true);
      const newUserInfo = form.getFieldValue();
      mutationUpdateUserInfo.mutate({
        id: userInfo.id,
        data: {
          name: newUserInfo.name,
          phone: newUserInfo.phone,
          email: userInfo.email,
          roles: userInfo.roles,
        },
      });
      return newUserInfo;
    },
  };
};
