import { css } from 'styled-components';

export const defaultStyle = css`
  padding: 1.75rem;
  border-radius: 0.75rem;
`;

export const secondaryCard = css`
  ${defaultStyle};
  background: ${({ theme }) => theme.SECONDARY};
`;

export const tertiaryCard = css`
  ${defaultStyle};
  background: ${({ theme }) => theme.TERTIARY};
`;

export const defaultCard = css`
  ${defaultStyle};
  background: white;
`;
