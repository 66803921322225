export default {
  'menu.member': 'Members',
  'menu.product': 'Products',
  'menu.apps': 'My Applications',
  'menu.billing': 'Billing Center',
  'menu.asc': 'Amity Social Cloud',
  'menu.liveChat': 'Live Chat',
  'menu.users': 'Members',
  'menu.devPortal': 'Developer Portal',
  'menu.faq': 'FAQ',
  'menu.section.help': 'Help center',
  'menu.organizationSettings': 'Organization Settings',
  'menu.organizationInfo': 'Organization info',
  'menu.paymentMethods': 'Payment methods',
  'menu.subscriptionAndLicenses': 'Subscription & Licenses',

  'errors.required': 'This field is required',

  'system.cancel': 'Cancel',
  'system.confirm': 'Confirm',

  'general.action.save': 'Save',
  'general.action.cancel': 'Cancel',
  'general.action.remove': 'Remove',

  'amplify.auth.error.personal.not.allow':
    'PreSignUp failed with error personal email is not allowed.',
  'amplify.auth.error.post.confirmation':
    'Sorry, this account is existed already, please sign in instead',

  'cognito.loginWithEmail.error.user.notConfirmed':
    'You have a pending verification, please complete the verification on your email.',

  'cim.no.permission.title': 'You don`t have permission for this page',
  'cim.no.permission.description':
    'You don`t have permission for this page please contact your global admin',

  'cim.member.list.page.title': 'Members',
  'cim.member.list.page.description': 'Manage your members',
  'cim.member.list.page.table.list.title': 'All members',
  'cim.member.list.page.form.filter.search.btn': 'Search',
  'cim.member.list.page.form.filter.search.placeholder': 'Search',
  'cim.member.list.page.form.filter.role.placeholder': 'Role',
  'cim.member.list.page.modal.delete.label': 'Are you sure you want to delete',
  'cim.member.list.page.modal.delete.description': '"{name}" will be deleted',
  'cim.member.list.page.modal.confirm.delete.btn': 'Delete',
  'cim.member.list.page.modal.cancel.delete.btn': 'Cancel',

  'cim.member.editor.page.breadcrumb.members': 'Members',
  'cim.member.editor.page.breadcrumb.view.profile': 'View profile',
  'cim.member.editor.page.update.success': 'Information updated successfuly',
  'cim.member.editor.page.delete.success': 'Deleted member successfuly',
  'cim.member.editor.page.modal.confirm.delete.btn': 'Delete',
  'cim.member.editor.page.modal.cancel.delete.btn': 'Cancel',
  'cim.member.editor.page.modal.delete.label': 'Are you sure you want to delete',
  'cim.member.editor.page.modal.delete.description': '"{name}" will be deleted',
  'member.detail.page.cancel.btn': 'Cancel',
  'member.detail.page.update.btn': 'Update',
  'member.detail.page.edit.btn': 'Edit',
  'member.detail.page.about.label': 'About',
  'member.detail.page.email.label': 'Email',
  'member.detail.page.telephone.label': 'Telephone',
  'member.detail.page.join.date.label': 'Joined date',
  'member.detail.page.remove.btn': 'Remove member',
  'member.detail.page.role.product.access.title': 'Roles and products access/role',
  'member.detail.page.product.access.label': 'Product access/role',

  'cim.change.password.page.title': 'Setup your password',
  'cim.change.password.page.password.label': 'Password',
  'cim.change.password.page.password.hint':
    'lowercase, uppercase, number, no special character, no space bar',
  'cim.change.password.page.change.password.btn': 'Continue',

  'cim.request.reset.password.page.title': 'Reset password',
  'cim.request.reset.password.page.email.required': 'Please enter your email!',
  'cim.request.reset.password.page.email.label': 'Enter your email',
  'cim.request.reset.password.page.email.placeholder': 'Email',
  'cim.request.reset.password.page.confirm.btn': 'Get Code',
  'cim.request.reset.password.page.goback.btn': 'Go back',

  'cim.reset.password.page.title': 'Reset password',
  'cim.reset.password.page.code.required': 'Please enter your code!',
  'cim.reset.password.page.code.label': 'Code',
  'cim.reset.password.page.code.placeholder': 'Code',
  'cim.reset.password.page.password.required': 'Please enter your password!',
  'cim.reset.password.page.password.label': 'Password',
  'cim.reset.password.page.password.hint':
    ' lowercase, uppercase, number, no special character, no space bar',
  'cim.reset.password.page.password.placeholder': 'Password',
  'cim.reset.password.page.confirm.btn': 'Reset',
  'cim.reset.password.page.goback.btn': 'Go back',

  'userInfo.page.edit.btn': 'Edit profile',
  'userInfo.page.update.btn': 'Update',
  'userInfo.page.cancel.btn': 'Cancel',
  'userInfo.page.detail.about.label': 'About',
  'userInfo.page.detail.email.label': 'Email',
  'userInfo.page.detail.joinDate.label': 'Joined date',
  'userInfo.page.product.access.label': 'Product access/role',
  'userInfo.page.detail.livechat.label': 'Livechat',
  'userInfo.page.detail.chatbot.label': 'Chatbot',
  'userInfo.page.setting.label': 'Setting',
  'userInfo.page.setting.profileName': 'Profile name',
  'userInfo.page.setting.phoneNumber': 'Phone number',
  'userInfo.page.reset.password.btn': 'Reset password',
  'userInfo.page.update.success': 'Information updated successfuly',

  'product.page.title': 'Products',
  'product.page.description': 'Products you can access',
  'product.page.no.product.access.title': 'You don’t have product access',
  'product.page.no.product.access.description':
    'You don’t have product access please contact your global admin',
  'product.page.chatbot.label': 'Chatbot',
  'product.page.chatbot.description': 'Lorem ipsum dolor sit amet consectetur.',
  'product.page.campaign.label': 'Campaign',
  'product.page.campaign.description': 'Lorem ipsum dolor sit amet consectetur.',

  'loginForm.header.newhere': 'New Here?',
  'loginForm.header.signup': 'Sign up',
  'loginForm.title': 'Sign in to Amity',
  'loginForm.email': 'Username',
  'loginForm.password': 'Password',
  'loginForm.forgetPassword': 'Forgot password?',
  'loginForm.email.placeholder': 'Username',
  'loginForm.password.placeholder': 'Password',
  'loginForm.email.required': 'Please enter your email',
  'loginForm.password.min.length': 'Your password must be 6 characters or more',
  'loginForm.password.required': 'Please enter your password',
  'loginForm.fail.verify.post.confirmation':
    'Sorry, this account is existed already, please sign in instead',
  'loginForm.success.verify.email':
    'Thanks for verifying your email! Your account is now ready for use.',
  'loginForm.signin.email.button': 'Log in',
  'loginForm.signin.google.button': 'Sign in with Google',
  'loginForm.reset.password.success':
    'If you have an account, the reset instruction will be sent to you shortly. Please check your email',
  'loginForm.pandt': 'By continuing, you agree to the {policy}',
  'loginForm.policy': 'Privacy Policy',
  'loginForm.term': 'Terms of Services',
  'loginForm.information.title': 'Get started for free with Amity Social Cloud',
  'loginForm.information.no.credit': 'No credit card required',
  'loginForm.information.free.uikit': 'Free UI Kit Basics',
  'loginForm.information.sla.uptime': '99.9% SLA uptime guarantee',
  'loginForm.information.customer.title': 'Powering social experiences for 10M+ end users',

  'signupForm.header.signin': 'Sign in',
  'signupForm.header.haveaccount': 'Have an account?',
  'signupForm.title': 'Sign up to Amity',
  'signupForm.email': 'Username',
  'signupForm.password': 'Password',
  'signupForm.email.placeholder': 'name@work-email.com',
  'signupForm.email.validation.required': 'Please enter your email.',
  'signupForm.email.validation.format': 'Please enter a valid email.',
  'signupForm.password.placeholder':
    '6+ characters with uppercase, lowercase, digit and special character',

  'signupForm.password.validation.required': 'Please enter your password',
  'signupForm.password.validation.length':
    'For security purposes, please set at least 6 characters in your password.',
  'signupForm.password.validation.lower':
    'For security purposes, please include at least one lowercase character in your password.',
  'signupForm.password.validation.upper':
    'For security purposes, please include at least one uppercase character in your password.',
  'signupForm.password.validation.numbers':
    'For security purposes, please include at least one numeric character in your password.',
  'signupForm.password.validation.special': `For security purposes, please include at least one symbol character in your password such as: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ = + -`,
  'signupForm.signup.email.button': 'Create account',
  'signupForm.signup.google.button': 'Sign up with Google',
  'signupForm.pandt': 'By continuing, you agree to the {policy} and {term}',
  'signupForm.policy': 'Privacy Policy',
  'signupForm.term': 'Terms of Conditions',
  'signupForm.information.title': 'Get started for free with Amity Social Cloud',
  'signupForm.information.no.credit': 'No credit card required',
  'signupForm.information.free.uikit': 'Free UI Kit Basics',
  'signupForm.information.sla.uptime': '99.9% SLA uptime guarantee',
  'signupForm.information.customer.title': 'Powering social experiences for 10M+ end users',

  'legalDocumentsDialog.action.back': 'Back',
  'legalDocumentsDialog.action.cancel': 'Cancel',
  'legalDocumentsDialog.action.confirm': 'Confirm',
  'legalDocumentsDialog.action.next': 'Next',
  'legalDocumentsDialog.privacyPolicy': 'Privacy Policy',
  'legalDocumentsDialog.termAndConditions': 'Terms & Conditions',
  'legalDocumentsDialog.privacyPolicy.checkbox.label':
    'I fully understand and agree to Privacy Policy',
  'legalDocumentsDialog.termAndConditions.checkbox.label':
    'I fully understand and agree to Terms and Conditions',

  'password.email.required': 'Please enter your email',
  'password.reset.title': 'Forgot your password?',
  'password.reset.description':
    'Don’t worry. Enter your email address to reset password. We’ll help you get back on track.',
  'password.reset.input.placeholder': 'name@work-email.com',
  'password.reset.label.email': 'Email',
  'password.reset.submit.button': 'Reset Password',
  'password.reset.back.button': 'Back',

  'password.renew.title': 'Please enter your new password',
  'password.renew.submit.button': 'Set new password',
  'password.renew.password.required': 'Please enter your password',
  'password.renew.label.password': 'Password',
  'password.renew.input.placeholder':
    '6+ characters with uppercase, lowercase, digit and special character',
  'password.renew.min.length': 'password must be 6 characters or more',
  'password.renew.label.confirmPassword': 'Confirm password',
  'password.renew.input.confirm.placeholder': 'Enter password',
  'password.renew.confirmPassword.require': 'Please enter your password',
  'password.renew.confirmPassword.notMatch': 'The password and confirmation password do not match',

  'verify.email.title': 'Awesome! Check your inbox',
  'verify.email.desctiption':
    'We’ve sent a verification link to {email} Please verify your email to activate your account',
  'verify.email.resend': 'Didn’t receive the email?',
  'verify.email.resend.link': 'Resend the confirmation email',
  'verify.email.resend.success': 'Resend code to success',
  'loginPage.submitButton': 'Log in',

  'initialSetup.information.title': 'One last step!',
  'initialSetup.information.detail':
    'This will help us issue the billing with the correct information. You can update the information anytime.',

  'usermenu.signout': 'Sign out',

  'apps.welcome': 'Welcome to Amity Portal!',
  'apps.welcome.prompt':
    'Amity Portal is your home for managing applications, billing, and other controls. Now let’s create your application!',
  'apps.gettingStarted': 'Getting started is easy',
  'apps.gettingStarted.prompt': 'Take a minute to kick off your project by creating an application',
  'apps.action.create': 'Create application',
  'apps.action.edit': 'Edit application',
  'apps.action.deactivate': 'Deactivate application',
  'apps.action.goToConsole': 'Go to console',
  'apps.state.active': 'Active',
  'apps.state.inactive': 'In active',
  'apps.state.temporary_inactive': 'In active',
  'apps.state.deactivated': 'Deactivated',
  'apps.state.initializing': 'Initializing',
  'apps.alert.inactive':
    'Your account has been deactivated. To reactivate your account, please reach out to our contact {link}.',
  'apps.alert.inactive.linkLabel': 'support',
  'apps.id': 'Application ID',
  'apps.createdAt': 'Created on',
  'apps.deactivatedAt': 'Deactivated on',
  'apps.plan': 'Pricing plan',
  'apps.serverRegion': 'Server Region',
  'apps.title': 'My Applications',
  'apps.prompt': 'You can create up to 10 active applications',
  'apps.form.id': 'Application ID',
  'apps.form.name': 'Application name',

  'editAppModal.modal.title': 'Edit application',
  'editAppModal.modal.prompt': 'Updating network name will not change ApplicationID',
  'editAppModal.form.error.emptyName': 'Please input your application name',
  'editAppModal.message.success': 'Your application has been updated',

  'deactivateAppModal.modal.title': 'Sure you want to deactivate application?',
  'deactivateAppModal.warning':
    'Once the application is deactivated, you will no longer be able to generate any new contents, but still can view the historical data in the  console.',
  'deactivateAppModal.confirmName': 'Please type {name} to proceed.',
  'deactivateAppModal.form.confirmName.placeholder': 'Enter application name',
  'deactivateAppModal.message.success': 'Your application has been deactivated',

  'apps.createApp.action.back': 'Back',
  'apps.createApp.action.confirmCreateApp': 'Confirm & Create application',
  'apps.createApp.action.next': 'Next',
  'apps.createApp.confirmation.appName': 'Application name',
  'apps.createApp.confirmation.pricingPlan': 'Pricing plan',
  'apps.createApp.confirmation.region': 'Server Region',
  'apps.createApp.confirmation.subTitle':
    'You won’t be able to change pricing plan after this step.',
  'apps.createApp.confirmation.title': 'Double check your application info',
  'apps.createApp.pricingCard.contactUs': 'Contact us',
  'apps.createApp.pricingCard.learnMore': 'Learn more',
  'apps.createApp.pricingCard.mau': 'MAU',
  'apps.createApp.setupApplication.appNameInput.title': 'Application name',
  'apps.createApp.setupApplication.appNameInput.description':
    'Your application name shouldn’t be longer than 64 characters',
  'apps.createApp.setupApplication.appNameInput.placeholder': 'ex. MyCoolApp',
  'apps.createApp.setupApplication.regionDropdown.title': 'Server region',
  'apps.createApp.setupApplication.regionDropdown.description':
    'Customers with high traffic, we recommend to choose region that close to your end user to get a better performance.',
  'apps.createApp.setupApplication.regionDropdown.placeholder': 'Choose region...',
  'apps.createApp.setupApplication.subTitle':
    'Your application name shouldn’t be longer than 64 characters',
  'apps.createApp.setupApplication.title': 'Setup application',
  'apps.createApp.selectPricingPlan.subTitle':
    'Don’t worry. No credit card required. We give you first 200 MAUs for free!',
  'apps.createApp.selectPricingPlan.title': 'Select pricing plan',
  'apps.createApp.step': 'Step {number}',
  'apps.createApp.submitDialog.error.action.back': 'Back',
  'apps.createApp.submitDialog.error.action.ok': 'OK',
  'apps.createApp.submitDialog.error.description.reachedLimit':
    'You have already reached the limit of applications.',
  'apps.createApp.submitDialog.error.description.default':
    "We're having technical issues at the moment. Please try again later.",
  'apps.createApp.submitDialog.error.title': 'Something went wrong',
  'apps.createApp.submitDialog.pending.description':
    'This may take a few seconds. Please do not cancel the process.',
  'apps.createApp.submitDialog.pending.title': 'Hang in there... We’re setting up your application',
  'apps.createApp.submitDialog.initialized.action.ok': 'OK',
  'apps.createApp.submitDialog.initialized.description':
    'Please sit back and relax, your application will be ready shortly',
  'apps.createApp.submitDialog.initialized.title':
    'Your application "{appName}" is still being initialized',
  'apps.createApp.submitDialog.success.action.goToConsole': 'Go to console',
  'apps.createApp.submitDialog.success.description':
    'Welcome to Amity Portal and thanks for joining us! Now let’s see what your application looks like',
  'apps.createApp.submitDialog.success.title': 'You’ve successfully created',
  'apps.createApp.title': 'Create application',

  'apps.createWSDialog.title': 'Help us set up your workspace',
  'apps.createWSDialog.description':
    'This will help your teammates recognize you. We promise it will take less than a minute!',
  'apps.createWSDialog.form.firstName.label': 'First name',
  'apps.createWSDialog.form.firstName.placeholder': 'ex. Jane',
  'apps.createWSDialog.form.lastName.label': 'Last name',
  'apps.createWSDialog.form.lastName.placeholder': 'ex. Doe',
  'apps.createWSDialog.form.organizationName.label': 'Which organization are you working for?',
  'apps.createWSDialog.form.organizationName.placeholder': 'ex. Coolapp',
  'apps.createWSDialog.submit': 'Save & Continue',

  // Billing
  'billing.title': 'Billing center',
  'billing.description': 'A place to manage your billings for all applications',
  'billing.header.title.serviceCharge': 'Service charge',
  'billing.header.title.serviceCredit': 'Service credit',
  'billing.header.title.miscellaneous': 'Miscellaneous',
  'billing.header.title.topUp': 'Top up',
  'billing.header.title.other': 'Others',
  'billing.header.action.viewStatement': 'View statement',
  'billing.header.action.viewInvoice': 'View invoice',
  'billing.header.action.viewReceipt': 'View receipt',
  'billing.header.action.payByCreditCard': 'Pay By Credit Card',
  'billing.card.balance.title': 'Current Balance',
  'billing.card.balance.description': 'Latest update: {latestDate}',
  'billing.card.plan.title': 'Current Plan',
  'billing.card.transactionId': 'Transaction ID',
  'billing.card.invoiceId': 'Invoice ID',
  'billing.card.dateAndTime': 'Date & Time',
  'billing.card.billingCycle': 'Billing cycle',
  'billing.note.title': 'Notes:',
  'billing.table.title': 'Transaction history',
  'billing.table.description': 'Check the lastest update on your account',
  'billing.table.search.placeholder': 'Search by invoice',
  'billing.table.header.invoiceId': 'Invoice ID',
  'billing.table.header.timestamp': 'Date & Time',
  'billing.table.header.invoice': 'Invoice type',
  'billing.table.header.transactionId': 'Transaction ID',
  'billing.table.header.amount': 'Amount',
  'billing.table.header.paymentStatus': 'Payment Status',
  'billing.table.type.serviceCharge': 'Service charge',
  'billing.table.type.topUp': 'Top up',
  'billing.table.type.serviceCredit': 'Service credit',
  'billing.table.type.miscellaneous': 'Miscellaneous',
  'billing.table.status.open': 'Open invoice',
  'billing.table.status.pending': 'Pending',
  'billing.table.status.paid': 'Paid',
  'billing.breadcrumb.billing': 'BILLING CENTER',
  'billing.paymentBreakdown.title': 'Payment breakdown',
  'billing.paymentBreakdown.itemLabel': 'Item',
  'billing.paymentBreakdown.amount': 'Amount',
  'billing.paymentBreakdown.creditAmount': 'Credit Amount',
  'billing.paymentBreakdown.paymentAmount': 'Payment Amount',
  'billing.paymentBreakdown.freeCredit': 'Free credit',
  'billing.paymentBreakdown.tax': 'VAT ({vat}%)',
  'billing.paymentBreakdown.action.viewTransaction': 'View transaction',
  'billing.transactionDetail.mau': 'MAU',
  'billing.transactionDetail.supportPlan': 'Support Plan',
  'billing.transactionDetail.mauUsage': 'MAU usage',
  'billing.transactionDetail.mauLicense': 'MAU License',
  'billing.transactionDetail.appId': 'Application ID',
  'billing.transactionDetail.price': 'Price',
  'billing.transactionDetail.item': 'Item',
  'billing.transactionDetail.quantity': 'Quantity',
  'billing.transactionDetail.amount': 'Amount',
  'billing.empty.search.title': 'No transactions found',
  'billing.empty.search.description': 'Try changing the search keywords',
  'billing.current.balance.reach.minimum':
    'All applications will be deactivated within 30 days. Please top up your balance.',
  'billing.error.loading.title': 'Aaah...Something went wrong',
  'billing.error.loading.description':
    "We're so sorry for the stumble. You can refresh the page or try again later.",

  'organization.setting.title': 'Organization Settings',
  'organization.setting.description': 'Manage all settings related to your organization account',
  'organization.setting.form.title': 'Setup your organization',
  'organization.setting.form.selection.billingInfo.title': 'Billing information',
  'organization.setting.form.selection.billingInfo.description':
    'This information will be included on all billing invoices on your account.',
  'organization.setting.form.organization.label': 'Organization name',
  'organization.setting.form.organizationId.label': 'Organization ID',
  'organization.setting.form.organization.description':
    'Your organization name is displayed in menus and headings.',
  'organization.setting.form.organization.error': 'Please enter organization name',
  'organization.setting.form.firstName.label': 'First name',
  'organization.setting.form.firstName.error': 'Please enter first name',
  'organization.setting.form.lastName.label': 'Last name',
  'organization.setting.form.lastName.error': 'Please enter last name',
  'organization.setting.form.billingName.label': 'Billing name',
  'organization.setting.form.billingName.error': 'Please enter billing name',
  'organization.setting.form.vatNumber.label': 'VAT Number',
  'organization.setting.form.vatNumber.error': 'Please enter company vat number',
  'organization.setting.form.billingAddress.label': 'Billing address',
  'organization.setting.form.billingAddress.error': 'Please enter address',
  'organization.setting.form.saveAndContinue': 'Save & Continue',
  'organization.setting.form.saveSetting': 'Save settings',
  'organization.setting.success': 'Your organization has been updated',
  'organization.setting.error': 'Something went wrong',

  'paymentMethods.title': 'Payment methods',
  'paymentMethods.prompt': 'Add and manage your payment methods using our secure payment system.',
  'paymentMethods.action.create': 'Add payment method',
  'paymentMethods.gettingStarted': 'Setup your card',
  'paymentMethods.gettingStarted.prompt':
    'Use your payment method to make purchases on Amity Portal',
  'paymentMethods.addForm.title': 'Add payment methods',
  'paymentMethods.deleteConfirmDialog.title': 'Remove credit card?',
  'paymentMethods.deleteConfirmDialog.description':
    'You are now about to remove card {cardNumber} from the account. You may then add a new payment method to the account',
  'paymentMethods.endingIn': 'ending in',
  'paymentMethods.expires': 'Expires',

  // Email verification link expired
  'verification.expired.title': 'Your verification link has expired',
  'verification.expired.description':
    'Not a big deal. Enter your email address associated with your account, and we’ll send the new verification link to you',
  'verification.expired.email.placeholder': 'name@work-email.com',
  'verification.expired.submit': 'Send verification email',
  'verification.expired.email.required': 'Please enter your email',
  'verification.expired.email.invalid': 'You entered an incorrect email',
  'verification.expired.email.resend.success': 'Send verfication link to your email success',
  'successTopUpModal.success': 'Successfully top up!',
  'successTopUpModal.success.prompt':
    'You have successfully top up {amount} to your account balance',
  'successTopUpModal.action.ok': 'Cool, thanks',

  'topUpBalance.title': 'Payment Method',
  'topUpBalance.title.freeCredit': 'How to get free credit?',
  'topUpBalance.prompt': 'Type or select the amount below',
  'topUpBalance.action.freeCredit': '+{freeCredit} free credit',
  'topUpBalance.action.topUp': 'Pay {amount} by credit card',
  'topUpBalance.action.goBackToAmityPortal': 'Go back to Amity Portal',
  'topUpBalance.action.learnMore': 'Learn more',
  'topUpBalance.form.error.invalidAmount':
    'Please enter a valid positive number and not less than 1',
  'topUpBalance.tooltip.freeCredit.maxCredit': 'Congrats! You’ve reached our maximum free credit!',
  'topUpBalance.tooltip.freeCredit':
    'Top up another {formattedAmountToAdd} to get {formattedTopUpSummary} on top!',
  'topUpBalance.tooltip.tax': 'Tax amount will be calculated based on the billing location',

  'topUpSummary.title': 'Payment Summary',
  'topUpSummary.amount': 'Top up',
  'topUpSummary.freeCredit': 'Free credit',
  'topUpSummary.subTotal': 'Subtotal',
  'topUpSummary.tax': 'VAT ({vat}%)',
  'topUpSummary.total': 'Total',
  'topUpSummary.invoiceId': 'Invoice ID',
  'topUpSummary.mauLicense': 'MAU License',
  'topUpSummary.mauUsage': 'MAU Usage',
  'topUpSummary.note': 'Note',
  'topUpSummary.supportPlan': 'Support Plan',
  'topUpSummary.miscellaneous': 'Miscellaneous',
  'topUpSummary.action.confirmAndTopUp': 'Confirm and pay',
  'topUpSummary.action.learnMore': 'Learn more',

  'paymentForm.paymentMethod': 'Payment Method',
  'paymentForm.saveNewPaymentCheckbox': 'Update my payment method with this card',
  'paymentForm.billingAddress': 'Billing Address',
  'paymentForm.firstName': 'First name',
  'paymentForm.firstName.error': 'Please enter a valid first name',
  'paymentForm.lastName': 'Last name',
  'paymentForm.lastName.error': 'Please enter a valid last name',
  'paymentForm.cardNumber': 'Card number',
  'paymentForm.cardNumber.error': 'Please enter a valid card number',
  'paymentForm.cardNumber.placeholder': 'Credit / Debit card number',
  'paymentForm.month': 'Month',
  'paymentForm.month.error': 'Please enter a valid month',
  'paymentForm.year': 'Year',
  'paymentForm.year.error': 'Please enter a valid year',
  'paymentForm.cvv.error': 'Please enter a valid CVV number',
  'paymentForm.address': 'Address',
  'paymentForm.address.error': 'Please enter a valid address',
  'paymentForm.address.placeholder': 'Building Number/Name, Street Name, District',
  'paymentForm.country': 'Country',
  'paymentForm.country.error': 'Please select a country',
  'paymentForm.country.placeholder': 'Select country',
  'paymentForm.city': 'City',
  'paymentForm.city.error': 'Please enter a valid city',
  'paymentForm.state': 'State',
  'paymentForm.state.error': 'Please select a state',
  'paymentForm.zip': 'Zip code',
  'paymentForm.zip.error': 'Please enter a valid zip code',

  'subscriptionAndLicenses.title': 'Subscription & Licenses',
  'subscriptionAndLicenses.description':
    'Check license detail and monitor MAU usage for all applications ',
  'subscriptionAndLicenses.supportPlan': 'Support Plan',
  'subscriptionAndLicenses.licenses': 'License',
  'subscriptionAndLicenses.planUsage': 'Plan usage',
  'subscriptionAndLicenses.licenseDetail': 'License detail',
  'subscriptionAndLicenses.MAUs': 'MAUs',
  'subscriptionAndLicenses.total': 'Total',
  'subscriptionAndLicenses.latestUpdate': 'Latest update',
};
