import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Font20600G5, Font14400G5 } from 'components/UtilitiesComponent';
import { Container, IconFrame, Inner } from './_style';

/**
 * @param {Array} icon - fontawesome icon array for example ['fal', 'ban']
 * @param {String} title - title of the result
 * @param {String} description - description of the result
 * @param {String} height - height of the result container
 */
const Result = ({ icon, title, description, height = 'calc(100vh - 60px)' }) => {
  return (
    <Container height={height}>
      <Inner>
        <IconFrame>
          <FontAwesomeIcon icon={icon} />
        </IconFrame>
        <Font20600G5>{title}</Font20600G5>
        <Font14400G5>{description}</Font14400G5>
      </Inner>
    </Container>
  );
};

export default Result;
