import { Auth } from 'aws-amplify';

import { clearCognitoCookie, exceptionHandler } from './utils';

const EXCEPTIONS = {
  UserNotConfirmedException: {
    'user is not confirmed': 'cognito.loginWithEmail.error.user.notConfirmed',
  },
};

const loginWithEmail = async ({ email, password }) => {
  if (!email || !password) throw new Error('Email or password is missing');
  clearCognitoCookie();
  return Auth.signIn(email.trim(), password).catch(e => exceptionHandler(e, EXCEPTIONS));
};

export default loginWithEmail;
