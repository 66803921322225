import React from 'react';

import Icon, { BellFilled } from '@ant-design/icons';
import styled from 'styled-components';

import { warningToast } from './styles';

const Container = styled.div`
  ${warningToast}
`;

const WarningToast = ({ children, align }) => {
  return (
    <Container align={align}>
      <Icon component={BellFilled} />
      {children}
    </Container>
  );
};

export default WarningToast;
