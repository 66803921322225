import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';

export const activateHotjar = (
  hotjarID = process?.env?.REACT_APP_HOTJAR_ID,
  hotjarSV = process?.env?.REACT_APP_HOTJAR_SV,
) => {
  if (hotjarID && hotjarSV) {
    hotjar.initialize(hotjarID, hotjarSV);
  }
};

export const activateGTM = (gtmId = process?.env?.REACT_APP_GTM_ID) => {
  if (gtmId) {
    TagManager.initialize({ gtmId });
  }
};

export function hotjarTrigger(...arg) {
  return window.hj?.(...arg);
}
