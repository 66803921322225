import React, { useCallback } from 'react';
import { Form, Typography } from 'antd';
import AmityLog from 'assets/images/amity_logo.svg';
import FormItem from 'design-system-components/FormItem';
import TextField from 'design-system-components/TextField';
import { DangerToast } from 'design-system-components/Toast';
import { Link, useHistory } from 'react-router-dom';
import { SecondaryButton, PrimaryButton } from 'design-system-components';
import { LoadingIcon } from 'components/LoadingIcon';
import { Font16500G3 } from 'components/UtilitiesComponent';
import { useTranslation } from 'hooks/useI18n';
import { useCimRequestResetPassword } from './useCimRequestResetPassword';
import { Container, FormInnerWrap } from './_style';

const RequestResetPassword = () => {
  const t = useTranslation();
  const { loading, errorMessage, onRequestResetPassword } = useCimRequestResetPassword();
  const history = useHistory();

  const getErrorToast = useCallback(() => {
    const errorFromHistory = history.location.state?.errorMessage;
    if (errorFromHistory) {
      return <DangerToast align="flex-start">{errorFromHistory}</DangerToast>;
    }
    if (errorMessage) {
      return <DangerToast align="flex-start">{errorMessage}</DangerToast>;
    }
    return null;
  }, [history.location.state, errorMessage]);

  return (
    <Container>
      <FormInnerWrap>
        <img src={AmityLog} width={138} height={40} alt="logo" />
        <Typography.Title level={4}>{t('cim.request.reset.password.page.title')}</Typography.Title>

        {getErrorToast()}

        <Form
          layout="vertical"
          onFinish={({ email }) => {
            onRequestResetPassword(email);
          }}
        >
          <FormItem
            name="email"
            rules={[
              {
                required: true,
                message: t('cim.request.reset.password.page.email.required'),
              },
            ]}
            label={<Font16500G3>{t('cim.request.reset.password.page.email.label')}</Font16500G3>}
          >
            <TextField
              size="large"
              placeholder={t('cim.request.reset.password.page.email.placeholder')}
            />
          </FormItem>
          <PrimaryButton htmlType="submit" size="large" disabled={loading}>
            {loading && <LoadingIcon icon={['fas', 'sync-alt']} />}{' '}
            {t('cim.request.reset.password.page.confirm.btn')}
          </PrimaryButton>
          <Link to="/login" tabIndex={-1}>
            <SecondaryButton size="large">
              {t('cim.request.reset.password.page.goback.btn')}
            </SecondaryButton>
          </Link>
        </Form>
      </FormInnerWrap>
    </Container>
  );
};

export default RequestResetPassword;
