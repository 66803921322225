import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useAmplifyAuth } from 'providers/AmplifyAuthProvider';
import { useHistory } from 'react-router-dom';

export const useCimChangePassword = () => {
  const { tempUser, setUser } = useAmplifyAuth();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (!tempUser.current) {
      return history.replace('/login');
    }
  }, [history, tempUser]);

  return {
    loading,
    errorMessage,
    onChangePassword: async password => {
      if (!tempUser.current) {
        return history.replace('/login');
      }
      try {
        setLoading(true);
        await Auth.completeNewPassword(tempUser.current, password);
        setLoading(false);
        setUser(tempUser.current);
        history.replace('/');
      } catch (error) {
        setErrorMessage(error.message);
        setLoading(false);
      }
    },
  };
};
