import { Auth } from 'aws-amplify';

import { clearCognitoCookie } from './utils';

const loginWithGoogle = async () => {
  clearCognitoCookie();
  return Auth.federatedSignIn({
    provider: 'Google',
  });
};

export default loginWithGoogle;
