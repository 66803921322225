import React, { useRef, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  padding: 1.8rem 1.5rem;
  border-radius: 0.75rem;
  background: white;
  display: grid;
  gap: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    flex: 1;
    word-break: break-word;
  }
  h5 {
    margin: 0;
    font-size: 1rem;
    color: ${({ theme }) => theme.TERTIARY} !important;
  }
  p {
    margin: 0;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.DESCRIPTION} !important;
  }
`;

const Button = styled.button`
  padding: 0.5rem;
  width: 36px;
  height: 36px;
  background: transparent;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.GHOST_FOCUS};
  }
  i {
    transition: all ease-out 0.3s;
    transform: ${({ active }) => (active ? `rotateX(180deg)` : `rotateX(0deg)`)};
  }
`;

const Content = styled.div`
  margin-top: 1rem;
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: ${({ height }) => `${height}px`};
`;

const Accordion = ({ header, description, children }) => {
  const [active, setActiveState] = useState(false);
  const [height, setHeightState] = useState(0);
  const contentRef = useRef();

  const toggle = () => {
    setActiveState(!active);
    setHeightState(active ? 0 : contentRef.current.scrollHeight);
  };

  return (
    <Container>
      <Header>
        <div>
          {header && <h5>{header}</h5>}
          {description && <p>{description}</p>}
        </div>
        <Button active={active} onClick={toggle}>
          <DownOutlined />
        </Button>
      </Header>
      <Content ref={contentRef} height={height}>
        <div> {children}</div>
      </Content>
    </Container>
  );
};

export default Accordion;
