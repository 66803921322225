import { client } from '.';

export const getTransactionIds = async term => {
  const { data } = await client.post(`/api/v1/transactions/search`, { term });
  return data;
};

export const getTransactions = async (page, sortBy) => {
  const { data } = await client.get(`/api/v1/transactions`, {
    params: {
      page,
      sortBy: sortBy ?? 'lastCreated',
    },
  });

  return data;
};

export const getTransactionById = async id => {
  const { data } = await client.get(`/api/v1/transactions/${id}`);
  return data;
};

export const getTransactionDetail = async (id, invoiceType) => {
  const { data } = await client.get(`/api/v1/transactions/${id}/details/${invoiceType}`);
  return data;
};

export const getTransactionReport = async id => {
  const { data } = await client.get(`/api/v1/transactions/${id}/report`);
  return data;
};

export const payInvoice = async id => {
  const { data } = await client.post(`/api/v1/transactions/${id}/pay`);
  return data;
};
