import React from 'react';
import Lottie from 'react-lottie-player';

import lottieJson from './lottie.json';

const LoadingCircle = ({ style, ...rest }) => {
  return <Lottie loop animationData={lottieJson} play style={style} {...rest} />;
};

export default LoadingCircle;
