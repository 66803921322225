import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAmplifyAuth } from 'providers/AmplifyAuthProvider';
import { useCimRole } from 'providers/CimRoleProvider';
import { isEmpty } from 'lodash';

export default ({ meta = {}, onPass, onFail, onLoading, ...rest }) => {
  const { user, isLoading: authIsLoading } = useAmplifyAuth();
  const { role, isLoading: roleIsLoading } = useCimRole();
  // const { account, isLoading: userInfoIsLoading } = useAccount();
  // const contractType = account?.['ASC:contractType'];
  const isValidAccount = !!user && !!role; // TODO && !!account;

  // if we are processing → onLoading
  if (authIsLoading || roleIsLoading) {
    return onLoading();
  }

  // logout route should always be reachable
  if (rest.path === '/logout') {
    return onPass({ meta, ...rest });
  }

  // is init it will call check user from cognito first
  if (isValidAccount && ['/login', '/signup'].includes(rest.path)) {
    return <Redirect to="/" />;
  }

  // // member list page
  if (isValidAccount && isEmpty(role.cim) && ['/member', '/member/:id'].includes(rest.path)) {
    return <Redirect to="/no-permission" />;
  }

  if (!meta?.auth) {
    // if account is not finished, force hand on setup
    // if (isValidAccount && !isAccountFilled && rest.path !== '/setup-organisation') {
    //   return <Redirect to="/setup-organisation" />;
    // }

    // if account is properly setup, we should not allow to go to setup-organisation
    // if (isValidAccount && isAccountFilled && rest.path === '/setup-organisation') {
    //   return <Redirect to="/" />;
    // }

    // if no need for auth → onPass
    return onPass({ meta, ...rest });
  }

  // if no roles required and already authed → onPass
  if (!meta?.roles && user) {
    return onPass({ meta, ...rest });
  }

  // if roles required and user in roles → onPass
  // if (user && meta?.roles?.includes(contractType)) {
  //   return onPass({ meta, ...rest });
  // }

  // in any other case, return fail (default is secure)
  return onFail({ meta, ...rest });
};
