import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormInnerWrap = styled.div`
  width: 324px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    margin-top: 16px;
  }
  form {
    margin-top: 24px;
    width: 100%;
    .ant-row:nth-child(2) {
      margin: 0;
    }
    button {
      width: 100%;
      margin-top: 16px;
    }
  }
`;
