import React from 'react';
import { SecondaryButton } from 'design-system-components';
import { Link } from 'react-router-dom';
import { Container, ProductName, ActionWrap } from './_style';

export default ({
  icon,
  label = 'label',
  // description = 'description',
  destUrl = window.location.href,
}) => {
  return (
    <Container>
      <img src={icon} width={48} height={36} alt="" />
      <ProductName>{label}</ProductName>
      {/* <ProductDescrtipion>{description}</ProductDescrtipion> */}
      <ActionWrap>
        <Link to={{ pathname: destUrl }} target="_blank">
          <SecondaryButton size="large">Discover</SecondaryButton>
        </Link>
      </ActionWrap>
    </Container>
  );
};
