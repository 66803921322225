import axios from 'axios';
import { parse, stringify } from 'qs';

import message from 'components/message';

const baseURL = window.localStorage.getItem('api_endpoint') ?? process.env.REACT_APP_API_ENDPOINT;

const client = axios.create({ baseURL });

const networkErrorHandler = () => {
  const isOnline = window.navigator.onLine;

  if (!isOnline) {
    message.error('No internet connection');
  } else {
    message.error('Network error');
  }
};

const unauthorizedHandler = () => {
  const { location } = window;
  const deployPath = location.pathname.match(/^\/\d+/s)?.[0] ?? '';
  if (location.pathname !== `${deployPath}/login`) {
    const { region, ...newParams } = parse(location.search, { ignoreQueryPrefix: true });
    const search = stringify({ ...newParams, redirect: location.pathname });
    location.href = `${deployPath}/login?${search}`;
  }
};

const globalAPIErrorHandler = error => {
  const newError = { ...error };
  const response = newError?.response;
  const status = response?.status;
  const errorMessage = response?.data?.message ?? response?.data;

  newError.catchedByGlobalHandler = true;

  if (!newError?.response) {
    networkErrorHandler();
  } else if (status === 401) {
    unauthorizedHandler();
  } else if (response?.data === 'No authorization token was found') {
    unauthorizedHandler();
  } else if (errorMessage) {
    message.error(errorMessage);
    newError.alreadyShowErrorMessage = true;
  }

  return Promise.reject(newError);
};

client.interceptors.response.use(
  response => response,
  error => globalAPIErrorHandler(error),
);

export { client };
