import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import FullScreenSpinner from 'components/FullScreenSpinner';
import { cimRoutes } from 'config/cim_routes';
import { hotjarTrigger } from 'utils/trackingAndAnalytics';

import LayoutRoute from './LayoutRoute';
import ProtectedRoute from './ProtectedRoute';

export default () => {
  const { pathname, search } = useLocation();
  useEffect(() => {
    hotjarTrigger('stateChange', pathname + search);
  }, [pathname, search]);

  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/product" />
      </Route>
      {/* routing is defined in config.js */}
      {cimRoutes.map(route => (
        <ProtectedRoute
          key={route.path}
          {...route}
          onLoading={() => <FullScreenSpinner />}
          onPass={props => <LayoutRoute {...props} />}
          onFail={() => <Redirect to="/login" />}
        />
      ))}

      <Route component={() => <div>404</div>} />
    </Switch>
  );
};
