import React from 'react';

import styled from 'styled-components';

import { defaultCard, secondaryCard, tertiaryCard } from './styles';

const Container = styled.div`
  ${({ variant }) => variant === 'default' && defaultCard}
  ${({ variant }) => variant === 'tertiary' && tertiaryCard}
  ${({ variant }) => variant === 'secondary' && secondaryCard}
`;

const Card = ({ variant = 'default', children, ...restProps }) => (
  <Container variant={variant} {...restProps}>
    <div>{children}</div>
  </Container>
);

export default Card;
