import { createGlobalStyle } from 'styled-components';

import { ERROR_MESSAGE_CSS_CLASS, SUCCESS_MESSAGE_CSS_CLASS } from 'components/message';

export const GlobalStyle = createGlobalStyle`
  .${ERROR_MESSAGE_CSS_CLASS},
  .${SUCCESS_MESSAGE_CSS_CLASS} {
    color: ${({ theme }) => theme.GREY_1};

    .ant-message-notice-content {
      min-height: 3rem;
      border-radius: 0.5rem;
      padding: 0.875rem 1rem;
      line-height: 1.25rem;
    }

    .ant-message-custom-content {
      display: flex;
      align-items: center;

      > svg {
        margin-bottom: -0.125rem;
        margin-right: 0.5rem;
      }
    }
  }

  .${ERROR_MESSAGE_CSS_CLASS} {
    .ant-message-notice-content {
      background: ${({ theme }) => theme.RED_LIGHT};
    }

    .ant-message-custom-content {
      > svg {
        color: ${({ theme }) => theme.RED};
      }
    }
  }

  .${SUCCESS_MESSAGE_CSS_CLASS} {
    .ant-message-notice-content {
      background: ${({ theme }) => theme.GREEN_LIGHT};
    }

    .ant-message-custom-content {
      > svg {
        color: ${({ theme }) => theme.GREEN};
      }
    }
  }
`;
