import ApplicationLayout from 'layouts/Application';
import Login from 'pages/cim_login';
import RequestResetPassword from 'pages/cim_request_reset_password';
import ResetPassword from 'pages/cim_reset_password';
import Product from 'pages/cim_product';
import ChangePassword from 'pages/cim_change_password';
import InternalError from 'pages/main/InternalError';
import CimMemberList from 'pages/cim_member_list';
import CimMemberEditor from 'pages/cim_member_editor';
import CimNoPermission from 'pages/cim_no_permission';
import CimUserProfile from 'pages/cim_user_profile';

export const cimRoutes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/error',
    component: InternalError,
  },
  {
    path: '/request-reset-password',
    component: RequestResetPassword,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
  {
    path: '/change-password',
    component: ChangePassword,
  },
  {
    path: '/member/:id',
    component: CimMemberEditor,
    meta: {
      layout: ApplicationLayout,
      auth: true,
    },
  },
  {
    path: '/member',
    component: CimMemberList,
    meta: {
      layout: ApplicationLayout,
      auth: true,
    },
  },
  {
    path: '/product',
    component: Product,
    meta: {
      layout: ApplicationLayout,
      auth: true,
    },
  },
  {
    path: '/no-permission',
    component: CimNoPermission,
    meta: {
      layout: ApplicationLayout,
      auth: true,
    },
  },
  {
    path: '/user-profile',
    component: CimUserProfile,
    meta: {
      layout: ApplicationLayout,
      auth: true,
    },
  },
];
