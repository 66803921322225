import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormInnerWrap = styled.div`
  width: 324px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    margin-top: 16px;
  }
  form {
    margin-top: 24px;
    width: 100%;
    .ant-row {
      margin-bottom: 16px;
    }
    button {
      width: 100%;
    }
    button:last-child {
      margin-top: 8px;
    }
  }
`;
