/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'hooks/useI18n';
import { Button, PrimaryButton } from '@amityco/diana-bot';
import { generateDate } from 'utils/generateDate';
import { UserAvatar } from '../UserAvatar';
import { UserInfoFromItem } from '../UserInfoFormItem';

import {
  RowWrap,
  FormContainer,
  ActionWrap,
  TitleGroup,
  InfoGroup,
  UserDetailLabel,
  UserDetailValue,
  RightSideGroup,
  ResetPasswordLink,
  LabelWrap,
  ProductAccessGroup,
  InfoWrap,
} from './_style';

export const UserInfoContent = ({
  isEditMode,
  userInfo,
  form,
  productAccessRole,
  onSwitchEditMode,
  onClickResetPassword,
  onCancelEdit,
  onUpdate,
}) => {
  const t = useTranslation();
  const _renderButton = () => {
    if (isEditMode) {
      return (
        <ActionWrap>
          <Button onClick={onCancelEdit}>{t('userInfo.page.cancel.btn')}</Button>
          <PrimaryButton htmlType="submit" onClick={onUpdate}>
            {t('userInfo.page.update.btn')}
          </PrimaryButton>
        </ActionWrap>
      );
    }

    return <Button onClick={onSwitchEditMode}>{t('userInfo.page.edit.btn')}</Button>;
  };

  return (
    <FormContainer id="cim-user-info">
      <RowWrap>
        <Col span={8} offset={4}>
          <UserAvatar username={userInfo?.name} userEmail={userInfo?.email} />
        </Col>
        <Col span={8}>
          <Row justify="end">
            <Col>{_renderButton()}</Col>
          </Row>
        </Col>
      </RowWrap>
      <RowWrap>
        <Col span={8} offset={4}>
          <InfoWrap>
            <InfoGroup>
              <TitleGroup> {t('userInfo.page.detail.about.label')}</TitleGroup>
              <Row wrap={false}>
                <LabelWrap>
                  <UserDetailLabel>{t('userInfo.page.detail.email.label')}</UserDetailLabel>
                </LabelWrap>
                <UserDetailValue>{userInfo?.email ?? '-'}</UserDetailValue>
              </Row>
              <Row wrap={false}>
                <LabelWrap>
                  <UserDetailLabel>{t('userInfo.page.detail.joinDate.label')}</UserDetailLabel>
                </LabelWrap>
                <UserDetailValue>{generateDate(userInfo?.createdAt) ?? '-'}</UserDetailValue>
              </Row>
            </InfoGroup>
            <ProductAccessGroup>
              <TitleGroup> {t('userInfo.page.product.access.label')}</TitleGroup>
              <Row wrap={false}>
                <LabelWrap>
                  <UserDetailLabel>{t('userInfo.page.detail.chatbot.label')}</UserDetailLabel>
                </LabelWrap>
                <UserDetailValue>{productAccessRole?.chatbotRole ?? '-'}</UserDetailValue>
              </Row>
            </ProductAccessGroup>
          </InfoWrap>
        </Col>
        <Col span={8}>
          <RightSideGroup data-testid="test-member-detail-form-item-group">
            <TitleGroup> {t('userInfo.page.setting.label')}</TitleGroup>
            <UserInfoFromItem form={form} userInfo={userInfo} isEditMode={isEditMode} />
            <ResetPasswordLink
              isEditMode={isEditMode}
              onClick={isEditMode ? onClickResetPassword : () => {}}
            >
              {t('userInfo.page.reset.password.btn')}
            </ResetPasswordLink>
          </RightSideGroup>
        </Col>
      </RowWrap>
    </FormContainer>
  );
};
