import React from 'react';
import { useTranslation } from 'hooks/useI18n';
import Result from 'components/Result';

const CimNoPermission = () => {
  const t = useTranslation();
  return (
    <Result
      icon={['fal', 'ban']}
      title={t('cim.no.permission.title')}
      description={t('cim.no.permission.description')}
      height="calc(100vh - 60px)"
    />
  );
};

export default CimNoPermission;
