import React from 'react';
import { Form, Row, Col } from 'antd';
import FormItem from 'design-system-components/FormItem';
import { SecondaryButton } from 'design-system-components/Button';
import TextField from 'design-system-components/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownSelectAll } from '@amityco/diana-bot';
import { useTranslation } from 'hooks/useI18n';

import { Container } from './_style';
import { useFormFilter } from './useFormFilter';

export const FormFilter = ({ options = [], onSearch }) => {
  const t = useTranslation();
  const {
    roleData,
    searchText,
    onDropDownChange,
    onDropdownClearAll,
    onDropdownSelectAll,
    onSearchTextChange,
  } = useFormFilter();
  return (
    <Container>
      <Form layout="horizontal">
        <Row gutter={[8, 0]}>
          <Col>
            <FormItem name="searchText">
              <TextField
                data-testid="test-search-input"
                type="text"
                placeholder={t('cim.member.list.page.form.filter.search.placeholder')}
                size="large"
                iconPrefix={<FontAwesomeIcon icon={['far', 'search']} />}
                value={searchText}
                onChange={e => onSearchTextChange(e.target.value)}
                onKeyDown={({ key }) => {
                  if (key === 'Enter') {
                    onSearch({ searchText, roleData });
                  }
                }}
              />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem name="role">
              <DropdownSelectAll
                size="large"
                data={options}
                placeHolder={t('cim.member.list.page.form.filter.role.placeholder')}
                selected={roleData}
                trigger={['click']}
                onClearAll={onDropdownClearAll}
                onSelectAll={onDropdownSelectAll}
                onSelectItem={onDropDownChange}
              />
            </FormItem>
          </Col>
          <Col>
            <SecondaryButton
              data-testid="test-search-btn"
              size="large"
              onClick={() => onSearch({ searchText, roleData })}
            >
              {t('cim.member.list.page.form.filter.search.btn')}
            </SecondaryButton>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
