import { useEffect, useState } from 'react';
import { useAmplifyAuth } from 'providers/AmplifyAuthProvider';
import { useHistory } from 'react-router-dom';

export const useCimResetPassword = () => {
  const { forgotPasswordSubmit } = useAmplifyAuth();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  useEffect(() => {
    const email = history.location.state?.email;
    if (!email) {
      history.replace('/request-reset-password');
    }
  }, [history]);

  return {
    loading,
    errorMessage,
    onResetPassword: async (code, newPassword) => {
      const email = history.location.state?.email;
      if (!email) {
        history.replace('/request-reset-password');
        return;
      }
      try {
        setLoading(true);
        await forgotPasswordSubmit(email, code, newPassword);
        setLoading(false);
        history.replace('/login');
      } catch (error) {
        process.env.NODE_ENV !== 'production' && console.error(error.code);
        setLoading(false);
        setErrorMessage(error.message);
      }
    },
  };
};
