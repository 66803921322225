import React, { useState, useContext, createContext, useMemo, useEffect } from 'react';
import { getRole } from 'api/role';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { useAmplifyAuth } from '../AmplifyAuthProvider';

export const CimRoleContext = createContext({ role: undefined, isLoading: false, isError: null });

export const useCimRole = () => useContext(CimRoleContext);

const CimRoleProvider = ({ children }) => {
  const history = useHistory();
  const { user } = useAmplifyAuth();
  const [role, setRole] = useState(null);
  const { isLoading, isError, refetch } = useQuery('memberRole', getRole, {
    enabled: !!user,
    onError: () => {
      history.replace('/error');
    },
    onSuccess: data => {
      setRole({
        ...data,
        products: data.products.map(x => {
          return {
            id: uuidv4(),
            name: x,
          };
        }),
      });
    },
  });

  useEffect(() => {
    if (!user) {
      setRole(null);
    } else {
      refetch();
    }
  }, [refetch, user]);

  const value = useMemo(() => {
    return {
      role,
      isLoading,
      isError,
    };
  }, [role, isLoading, isError]);

  return <CimRoleContext.Provider value={value}>{children}</CimRoleContext.Provider>;
};

export default CimRoleProvider;
