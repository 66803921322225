import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

export const UserNameAndEmailWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.span`
  height: fit-content;
  font-weight: 500;
  font-size: 24px;
  color: #212121;
  line-height: 1;
`;
export const UserEmail = styled.span`
  height: 16px;
  font-weight: 400;
  font-size: 12px;
  color: #616161;
`;
