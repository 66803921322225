import styled from 'styled-components';

export const AvatarContainer = styled.div`
  display: inline-block;
  border-radius: 9999px;
  background-color: rgba(0, 0, 0, 0.04);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  ${({ backgroundImage }) => backgroundImage && `background-image: url(${backgroundImage});`}

  ${({ size }) => {
    if (size) {
      return `
        width: ${size}px;
        height: ${size}px;
      `;
    }

    return null;
  }}
`;
