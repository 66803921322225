import { Button as AntdButton } from 'antd';
import styled from 'styled-components';

import {
  DANGER_BUTTON,
  DEFAULT_BUTTON,
  GHOST_BUTTON,
  LARGE_BUTTON,
  MIDDLE_BUTTON,
  PRIMARY_BUTTON,
  TERTIARY_BUTTON,
  SECONDARY_BUTTON,
} from './styles';

const AbstractButton = styled(AntdButton)`
  ${({ size = 'middle' }) => {
    if (size === 'middle') {
      return MIDDLE_BUTTON;
    }

    if (size === 'large') {
      return LARGE_BUTTON;
    }
  }}
`;

export const Button = styled(AbstractButton).attrs(() => ({ type: 'default' }))`
  ${DEFAULT_BUTTON}
`;

export const PrimaryButton = styled(AbstractButton).attrs(() => ({ type: 'primary' }))`
  ${PRIMARY_BUTTON}
`;

export const DangerButton = styled(AbstractButton).attrs(() => ({ danger: true }))`
  ${DANGER_BUTTON}
`;

export const GhostButton = styled(AbstractButton).attrs(() => ({ type: 'ghost' }))`
  ${GHOST_BUTTON}
`;

export const TertiaryButton = styled(AbstractButton)`
  ${TERTIARY_BUTTON}
`;

export const SecondaryButton = styled(AbstractButton)`
  ${SECONDARY_BUTTON}
`;
