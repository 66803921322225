import React from 'react';
import ProductCard from 'components/ProductCard';
import Robot from 'assets/images/robot.svg';
import Calendar from 'assets/images/calendar.svg';
import Row from 'components/Row';
import { Col } from 'antd';
import { useCimRole } from 'providers/CimRoleProvider';
import { Font32600G1, Font16500G3 } from 'components/UtilitiesComponent';
import { useTranslation } from 'hooks/useI18n';
import Result from 'components/Result';

import { Container, ProductsContainer } from './_style';

const Product = () => {
  const t = useTranslation();
  const { role } = useCimRole();

  if (!role) return null;

  return (
    <Container>
      <React.Fragment>
        <div>
          <Font32600G1>{t('product.page.title')}</Font32600G1>
        </div>
        <div>
          <Font16500G3>{t('product.page.description')}</Font16500G3>
        </div>
        {role?.products?.length > 0 ? (
          <ProductsContainer>
            <Row>
              {role?.products.map(product => {
                if (product.name === 'chatbot') {
                  return (
                    <Col>
                      <ProductCard
                        key={product.id}
                        icon={Robot}
                        label={t('product.page.chatbot.label')}
                        description={t('product.page.chatbot.description')}
                        destUrl={
                          process.env.REACT_APP_PRODUCT_URL.startsWith('bots')
                            ? `https://${process.env.REACT_APP_PRODUCT_URL}`
                            : `https://megabot.${process.env.REACT_APP_PRODUCT_URL}`
                        }
                      />
                    </Col>
                  );
                }
                if (product.name === 'campaign') {
                  return (
                    <Col>
                      <ProductCard
                        key={product.id}
                        icon={Calendar}
                        label={t('product.page.campaign.label')}
                        description={t('product.page.campaign.description')}
                        destUrl={
                          process.env.REACT_APP_PRODUCT_URL.startsWith('bots')
                            ? `https://${process.env.REACT_APP_PRODUCT_URL}/campaign/chatads`
                            : `https://megabot.${process.env.REACT_APP_PRODUCT_URL}/campaign/chatads`
                        }
                      />
                    </Col>
                  );
                }
                return null;
              })}
            </Row>
          </ProductsContainer>
        ) : (
          <Result
            height="calc(100vh - 135px)"
            icon={['fal', 'ban']}
            title={t('product.page.no.product.access.title')}
            description={t('product.page.no.product.access.description')}
          />
        )}
      </React.Fragment>
    </Container>
  );
};

export default Product;
