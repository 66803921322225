import React, { useMemo } from 'react';
import { Table } from '@amityco/diana-bot';
import { Font16600G1 } from 'components/UtilitiesComponent';
import { useHistory } from 'react-router';
import { useCimRole } from 'providers/CimRoleProvider';
import { useTranslation } from 'hooks/useI18n';
import { TableColumnHeader } from './TableColumnHeader';

export const TableList = ({ data, onDeleteMember }) => {
  const t = useTranslation();
  const history = useHistory();
  const { role } = useCimRole();
  const isShowDeleteBtn = useMemo(() => {
    return !!role.cim?.members?.apis?.find(
      api => api.id === '/api/v1/members/:id' && api.action === 'full',
    );
  }, [role.cim]);

  return (
    <Table
      scroll={{ y: 'calc(100vh - 400px)' }}
      header={<Font16600G1>{t('cim.member.list.page.table.list.title')}</Font16600G1>}
      data={data}
      columns={TableColumnHeader(history, isShowDeleteBtn, onDeleteMember, role.subscribedProducts)}
      pagination={false}
    />
  );
};
