import { css } from 'styled-components';

export const defaultStyle = css`
  width: 100%;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: ${({ align = 'center' }) => align};
  align-items: flex-start;
  color: ${({ theme }) => theme.BLACK};

  .anticon {
    font-size: 1rem;
    margin: 0.125rem 0.25rem 0 0;
  }
`;

export const successToast = css`
  ${defaultStyle};
  background-color: ${({ theme }) => theme.GREEN_LIGHT};
  border: 1px solid ${({ theme }) => theme.GREEN};
  .anticon {
    color: ${({ theme }) => theme.GREEN};
  }
`;

export const failToast = css`
  ${defaultStyle};

  background-color: ${({ theme }) => theme.RED_LIGHT};
  border: 1px solid ${({ theme }) => theme.RED};
  .anticon {
    color: ${({ theme }) => theme.RED};
  }
`;

export const warningToast = css`
  ${defaultStyle}
  background-color: ${({ theme }) => theme.YELLOW_LIGHT};
  border: 1px solid ${({ theme }) => theme.YELLOW};
  .anticon {
    color: ${({ theme }) => theme.YELLOW};
  }
`;
