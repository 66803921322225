import { CIM_ROLE_FILTER } from 'constants/cim_role_filter';
import { useCimRole } from 'providers/CimRoleProvider';
import { useState } from 'react';

export const useFormFilter = () => {
  const [searchText, setSearchText] = useState('');
  const [roleData, setRoleData] = useState([]);
  const { role } = useCimRole();

  const onSearchTextChange = value => {
    setSearchText(value);
  };

  const onDropDownChange = data => {
    setRoleData(prev => {
      const copyData = prev.slice();
      const index = copyData.findIndex(item => item.value === data.value);
      if (index > -1) {
        copyData.splice(index, 1);
      } else {
        copyData.push(data);
      }
      return copyData;
    });
  };

  const onDropdownClearAll = () => {
    setRoleData([]);
  };
  const onDropdownSelectAll = () => {
    setRoleData(
      CIM_ROLE_FILTER.filter(item => role.subscribedProducts.includes(item.group.toLowerCase())),
    );
  };

  return {
    roleData,
    searchText,
    onSearchTextChange,
    onDropDownChange,
    onDropdownClearAll,
    onDropdownSelectAll,
  };
};
