// @ts-nocheck
import React, { useCallback } from 'react';

import { useI18n } from 'hooks';
import { Form } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import FormItem from 'design-system-components/FormItem';
import TextField from 'design-system-components/TextField';
import { PrimaryButton } from 'design-system-components/Button';
import { DangerToast } from 'design-system-components/Toast';
import { LoadingIcon } from 'components/LoadingIcon';
import { PasswordInput } from 'design-system-components';
import { useCimLogin } from './useCimLogin';

import { Container, Logo, ActionWrap, ForgotPassword, Version } from './_style';

const Login = () => {
  const { t } = useI18n();
  const { errorMessage, loading, form, onLogin } = useCimLogin();
  const history = useHistory();
  const getErrorToast = useCallback(() => {
    const errorFromHistory = history.location.state?.errorMessage;
    if (errorFromHistory) {
      return <DangerToast align="flex-start">{errorFromHistory}</DangerToast>;
    }
    if (errorMessage) {
      return <DangerToast align="flex-start">{errorMessage}</DangerToast>;
    }
    return null;
  }, [history.location.state, errorMessage]);
  return (
    <Container>
      <Logo />
      {getErrorToast()}
      <Form layout="vertical" form={form} onFinish={onLogin}>
        <FormItem
          name="email"
          rules={[
            {
              required: true,
              message: t('loginForm.email.required'),
            },
          ]}
        >
          <TextField
            placeholder={t('loginForm.email.placeholder')}
            size="large"
            autoComplete="new-password"
          />
        </FormItem>
        <FormItem
          name="password"
          rules={[
            {
              required: true,
              message: t('loginForm.password.required'),
            },
          ]}
        >
          <PasswordInput
            placeholder={t('loginForm.password.placeholder')}
            size="large"
            autoComplete="new-password"
            type="password"
          />
        </FormItem>
        <ActionWrap>
          <PrimaryButton htmlType="submit" size="large" disabled={loading}>
            {loading && <LoadingIcon icon={['fas', 'sync-alt']} />}
            {t('loginForm.signin.email.button')}
          </PrimaryButton>
          <Link to="/request-reset-password">
            <ForgotPassword>Forgot password?</ForgotPassword>
          </Link>
        </ActionWrap>
        <Version>{__VERSION__}</Version>
      </Form>
    </Container>
  );
};

export default Login;
