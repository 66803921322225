import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  background: ${({ theme }) => theme.GREY_9}};
  border-radius: 12px;
  max-width: 237px;
  width: 237px;
`;

export const ProductName = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.GREY_1}};
`;

export const ProductDescrtipion = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.GREY_5}};
`;

export const ActionWrap = styled.div`
  width: 100%;
  button {
    width: 100%;
  }
`;
