import { Avatar } from '@amityco/diana-bot';
import React from 'react';
import { Container, UserNameAndEmailWrap, UserName, UserEmail } from './_style';

export const UserAvatar = ({
  imgUrl = 'https://picsum.photos/200/300',
  username = 'username',
  userEmail = 'user@email.co',
}) => {
  return (
    <Container>
      <Avatar imgUrl={imgUrl} />
      <UserNameAndEmailWrap>
        <UserName>{username}</UserName>
        <UserEmail>{userEmail}</UserEmail>
      </UserNameAndEmailWrap>
    </Container>
  );
};
