import styled from 'styled-components';
import { Col, Form, Row } from 'antd';

export const FormContainer = styled(Form)`
  padding-top: 40px;
  width: 100%;
  height: calc(100vh - 82px);
  display: flex;
  flex-direction: column;
`;

export const ActionWrap = styled.div`
  display: flex;
  column-gap: 8px;
  button {
    width: 100px;
    height: 36px;
  }
`;

export const TitleGroup = styled.p`
  margin: 24px 0 8px;
  font-weight: 500;
  font-size: 16px;
  color: #212121;
`;

export const AboutGroup = styled.div`
  padding: 16px;
  background: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const UserDetailLabel = styled.label`
  color: #9e9e9e;
  font-weight: 400;
  font-size: 16px;
`;

export const UserDetailValue = styled.p`
  word-break: break-word;
  margin: 0;
  color: #616161;
  font-weight: 400;
  font-size: 16px;
`;

export const ButtonWrap = styled.div`
  button {
    width: 100%;
  }
`;

// RightSideGroup
export const RightSideGroup = styled.div`
  padding-left: 24px;
  .ant-form-item {
    margin-bottom: 12px;
  }
`;

export const ProductAccessWord = styled.p`
  margin: 16px 0 0 0;
  font-weight: 600;
  font-size: 16px;
  color: #212121;
`;

export const Spacing = styled.div`
  height: 16px;
`;

export const RowContent = styled(Row)`
  flex: 1;
`;

export const ColContent = styled(Col)`
  padding-right: 24px;
  border-right: 1px solid #e5e5e5;
`;
