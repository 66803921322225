export const CIM_ROLE_FILTER = [
  {
    group: 'CHATBOT',
    label: 'Admin',
    value: 'chatbot.admin',
  },
  {
    group: 'CHATBOT',
    label: 'Developer',
    value: 'chatbot.developer',
  },
  {
    group: 'CHATBOT',
    label: 'Editor',
    value: 'chatbot.editor',
  },
  {
    group: 'CHATBOT',
    label: 'Moderator',
    value: 'chatbot.moderator',
  },
  {
    group: 'CAMPAIGN',
    label: 'Admin',
    value: 'campaign.admin',
  },
  {
    group: 'CAMPAIGN',
    label: 'Editor',
    value: 'campaign.editor',
  },
  {
    group: 'CAMPAIGN',
    label: 'Moderator',
    value: 'campaign.moderator',
  },
];

export const CIM_API_MEMBER_LIST_MAX_TAKE = 10;
