import React from 'react';

import Icon, { CheckCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

import { successToast } from './styles';

const Container = styled.div`
  ${successToast}
`;

const SuccessToast = ({ children, align }) => {
  return (
    <Container align={align}>
      <Icon component={CheckCircleFilled} />
      {children}
    </Container>
  );
};

export default SuccessToast;
