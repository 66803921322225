export const RESET_PASSWORD_PATH = '/reset-password';
export const USER_PROFILE_PATH = '/user-profile';

export const SIDE_BAR_MEMBER = {
  iconName: 'users',
  label: 'Members',
  pageName: 'members',
  path: '/member',
};

export const SIDE_BAR_PRODUCT = {
  iconName: 'cubes',
  label: 'Products',
  pageName: 'products',
  path: '/product',
};
