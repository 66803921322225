import React, { useCallback } from 'react';
import { Form, Typography } from 'antd';
import AmityLog from 'assets/images/amity_logo.svg';
import FormItem from 'design-system-components/FormItem';
import { DangerToast } from 'design-system-components/Toast';
import { useHistory } from 'react-router-dom';
import { PrimaryButton, PasswordInput } from 'design-system-components';
import { LoadingIcon } from 'components/LoadingIcon';
import { Font16500G3, Font12500G5 } from 'components/UtilitiesComponent';
import { useTranslation } from 'hooks/useI18n';
import { useCimChangePassword } from './useCimChangePassword';
import { Container, FormInnerWrap } from './_style';

const ChangePassword = () => {
  const t = useTranslation();
  const { loading, errorMessage, onChangePassword } = useCimChangePassword();
  const history = useHistory();

  const getErrorToast = useCallback(() => {
    const errorFromHistory = history.location.state?.errorMessage;
    if (errorFromHistory) {
      return <DangerToast align="flex-start">{errorFromHistory}</DangerToast>;
    }
    if (errorMessage) {
      return <DangerToast align="flex-start">{errorMessage}</DangerToast>;
    }
    return null;
  }, [history.location.state, errorMessage]);

  return (
    <Container>
      <FormInnerWrap>
        <img src={AmityLog} width={138} height={40} alt="logo" />
        <Typography.Title level={4}>{t('cim.change.password.page.title')}</Typography.Title>

        {getErrorToast()}

        <Form
          layout="vertical"
          onFinish={({ password }) => {
            onChangePassword(password);
          }}
        >
          <FormItem
            name="password"
            rules={[
              {
                required: true,
              },
            ]}
            label={<Font16500G3>{t('cim.change.password.page.password.label')}</Font16500G3>}
            help={<Font12500G5>{t('cim.change.password.page.password.hint')}</Font12500G5>}
          >
            <PasswordInput type="password" />
          </FormItem>
          <PrimaryButton htmlType="submit" size="large" disabled={loading}>
            {loading && <LoadingIcon icon={['fas', 'sync-alt']} />}
            {t('cim.change.password.page.change.password.btn')}
          </PrimaryButton>
        </Form>
      </FormInnerWrap>
    </Container>
  );
};

export default ChangePassword;
