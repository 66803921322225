import styled from 'styled-components';
import { Col, Form, Row } from 'antd';

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export const ActionWrap = styled.div`
  display: flex;
  column-gap: 8px;
  button {
    width: 100px;
    height: 36px;
  }
`;

export const TitleGroup = styled.p`
  margin: 24px 0 16px;
  font-weight: 500;
  font-size: 16px;
  color: #212121;
`;

export const InfoWrap = styled.div`
  height: 100%;
  height: calc(100vh - 115px);
  border-right: 1px solid #e5e5e5;
  padding-right: 24px;
`;

export const InfoGroup = styled.div`
  margin-bottom: 24px;
`;

export const UserDetailLabel = styled.label`
  color: #9e9e9e;
  font-weight: 400;
  font-size: 16px;
`;

export const UserDetailValue = styled.p`
  word-break: break-word;
  margin: 0;
  color: #616161;
  font-weight: 400;
  font-size: 16px;
`;

// RightSideGroup
export const RightSideGroup = styled.div`
  width: 324px;
  padding-left: 24px;
  .ant-form-item {
    margin-bottom: 12px;
  }
`;

export const RowWrap = styled(Row)`
  width: 100%;
`;

export const Spacing = styled.div`
  height: 16px;
`;

export const RowContent = styled(Row)`
  flex: 1;
`;

export const ColContent = styled(Col)`
  padding-right: 24px;
`;

export const ResetPasswordLink = styled.p`
  font-size: 16px;
  margin: 24px 0;
  color: ${({ isEditMode }) => (isEditMode ? '#04be8c' : '#B4EBDD')};
  cursor: ${({ isEditMode }) => (isEditMode ? 'pointer' : 'not-allowed')};
`;

export const LabelWrap = styled.div`
  min-width: 120px;
`;

export const ProductAccessGroup = styled.div``;
