import React from 'react';
import { IntlProvider } from 'react-intl';

import en from './en';

const DEFAULT_LOCALE = 'en';
const LOCALES = { en };

export default ({ locale, ...props }) => {
  const messages = locale in LOCALES ? LOCALES[locale] : LOCALES[DEFAULT_LOCALE];

  return (
    <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={locale} messages={messages} {...props} />
  );
};
