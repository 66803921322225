import React from 'react';

import { WarningFilled } from '@ant-design/icons';
import { message as antdMessage } from 'antd';

import { ReactComponent as TickIcon } from 'assets/icons/filled-tick.svg';

export const ERROR_MESSAGE_CSS_CLASS = 'customErrorMessage';
export const SUCCESS_MESSAGE_CSS_CLASS = 'customSuccessMessage';

function error(content, duration, onClose) {
  return antdMessage.error({
    icon: <WarningFilled />,
    className: ERROR_MESSAGE_CSS_CLASS,
    content,
    duration,
    onClose,
  });
}

function success(content, duration, onClose) {
  return antdMessage.success({
    icon: <TickIcon />,
    className: SUCCESS_MESSAGE_CSS_CLASS,
    content,
    duration,
    onClose,
  });
}

export default { ...antdMessage, error, success };
