export const CHATBOT_ROLE_LABELS_MAPPING = {
  'chatbot.admin': 'Admin',
  'chatbot.developer': 'Developer',
  'chatbot.editor': 'Editor',
  'chatbot.moderator': 'Moderator',
};

export const AVATAR_SIZE = {
  small: 24,
  medium: 32,
};

export const AVATAR_DEFAULT_URL = 'https://picsum.photos/200/300';
