import styled from 'styled-components';

import amityLogo from 'assets/images/amity_logo.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  height: 100vh;
  padding: 0 12px;
  margin: 0 auto;
  form {
    margin-top: 24px;
  }
`;

export const Logo = styled.div`
  height: 4rem;
  background-image: url(${amityLogo});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 2.875rem;
`;

export const ActionWrap = styled.div`
  button {
    width: 100%;
  }
`;

export const Version = styled.div`
  text-align: center;
  width: 100%;
  color: rgba(0, 0, 0, 0.18);
  font-weight: 400;
  font-size: 14px;
`;

export const ForgotPassword = styled.a`
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  margin-top: 16px;
  color: #9e9e9e !important;
  cursor: pointer;
  width: 100%;
  display: block;
`;
