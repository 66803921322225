import find from 'lodash/find';
import isString from 'lodash/isString';

import { makeReject } from 'utils/promise';

export const exceptionHandler = (error, exception) => {
  if (typeof error === 'string') {
    return makeReject(error, null);
  }

  const matchingException = exception[error.code];

  if (!matchingException) return makeReject(error);

  if (!isString(error.message)) return makeReject(error);

  const matchingMessageId = find(matchingException, (_, key) =>
    error.message.toLowerCase().includes(key),
  );

  if (!matchingMessageId) return makeReject(error);

  return makeReject(error, { messageId: matchingMessageId });
};

export const clearCognitoCookie = () => {
  if (document.cookie) {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i];
      if (cookie.toLowerCase().includes('cognito') || cookie.toLowerCase().includes('amplify')) {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${
          process.env.NODE_ENV === 'production' ? '.amity.co' : 'localhost'
        }`;
      }
    }
  }
};
