import { useReducer, useRef, useState } from 'react';
import { getMembers, deleteMember } from 'api/member';
import { useQuery, useMutation } from 'react-query';
import { CIM_API_MEMBER_LIST_MAX_TAKE } from 'constants/cim_role_filter';
import { filterReducer, INITIAL_FILTER_DATA } from './reducer';

export const useCimMemberList = () => {
  // evertime filterData updated it will call getMemberData from react-query
  const [filterData, dispatch] = useReducer(filterReducer, INITIAL_FILTER_DATA);
  const [memberList, setMemberListData] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const tempSelectedMemberId = useRef('');
  const tempCursorId = useRef({
    prevCursorId: '',
    nextCursorId: '',
    currentPage: 1,
  });

  const onSearch = ({ searchText, roleData, next, prev }) => {
    if (searchText || roleData) {
      dispatch({ type: 'UPDATE_KEYWORD_AND_ROLES', payload: { searchText, roleData } });
      return;
    }

    if (next) {
      dispatch({ type: 'UPDATE_BEHAVIOR', payload: 'next' });
      return;
    }

    if (prev) {
      dispatch({ type: 'UPDATE_BEHAVIOR', payload: 'prev' });
    }
  };

  const getMemberData = ({ keyword, roles, behavior }) => {
    if (behavior === 'next') {
      return getMembers({
        keyword,
        take: CIM_API_MEMBER_LIST_MAX_TAKE + 1,
        roles,
        cursorId: tempCursorId.current.nextCursorId,
      });
    }
    if (behavior === 'prev') {
      return getMembers({
        keyword,
        take: -CIM_API_MEMBER_LIST_MAX_TAKE,
        roles,
        cursorId: tempCursorId.current.prevCursorId,
      });
    }
    return getMembers({
      keyword,
      take: CIM_API_MEMBER_LIST_MAX_TAKE + 1,
      roles,
    });
  };

  const { isFetching } = useQuery(['get-members', filterData], () => getMemberData(filterData), {
    onSuccess: d => {
      if (filterData.behavior === 'none') {
        let itemPop = null;
        if (d.data.length > CIM_API_MEMBER_LIST_MAX_TAKE) {
          itemPop = d.data.pop();
        }
        tempCursorId.current.nextCursorId = itemPop ? d.data[d.data.length - 1].id : '';
        setMemberListData(d.data);
      } else if (filterData.behavior === 'next') {
        let itemPop = null;
        if (d.data.length > CIM_API_MEMBER_LIST_MAX_TAKE) {
          itemPop = d.data.pop();
        }
        tempCursorId.current.nextCursorId = itemPop ? d.data[d.data.length - 1].id : '';
        tempCursorId.current.prevCursorId =
          d.data.length > 0 ? d.data[0].id : tempCursorId.current.nextCursorId;
        tempCursorId.current.currentPage += 1;
        setMemberListData(d.data);
      } else {
        tempCursorId.current.nextCursorId =
          d.data.length > 0 ? d.data[d.data.length - 1].id : tempCursorId.current.nextCursorId;
        tempCursorId.current.prevCursorId =
          d.data.length > 0 ? d.data[0].id : tempCursorId.current.nextCursorId;
        tempCursorId.current.currentPage -= 1;
        setMemberListData(d.data);
      }
    },
  });

  const mutation = useMutation(
    memberId => {
      return deleteMember(memberId);
    },
    {
      onError: () => {
        setIsDeleting(false);
        setDeleteModalOpen(false);
        tempSelectedMemberId.current = '';
      },
      onSuccess: () => {
        tempCursorId.current = {
          prevCursorId: '',
          nextCursorId: '',
          currentPage: 1,
        };
        setTimeout(() => {
          dispatch({ type: 'UPDATE_BEHAVIOR', payload: 'none' });
          setIsDeleting(false);
          setDeleteModalOpen(false);
          tempSelectedMemberId.current = '';
        }, 3000);
      },
    },
  );

  const onConfirmDelete = () => {
    if (tempSelectedMemberId.current) {
      setIsDeleting(true);
      mutation.mutate(tempSelectedMemberId.current);
    }
  };

  const onDeleteMember = memberId => {
    tempSelectedMemberId.current = memberId;
    setDeleteModalOpen(true);
  };

  const onCancelDelete = () => {
    if (!isDeleting) {
      setDeleteModalOpen(false);
      tempSelectedMemberId.current = '';
    }
  };

  return {
    isDeleteModalOpen,
    memberList,
    filterData,
    tempCursorId: tempCursorId.current,
    tempSelectedMemberId: tempSelectedMemberId.current,
    isFetching,
    mutation,
    isDeleting,
    onSearch,
    onDeleteMember,
    onCancelDelete,
    onConfirmDelete,
  };
};
