import React from 'react';

import { Input } from 'antd';
import styled from 'styled-components';

const TextInput = styled(Input)`
  border: 1px solid ${({ theme }) => theme.GREY_7};
  border-radius: 0.5rem;

  &:hover,
  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.GREY_7};
    box-shadow: none;
  }

  ${({ theme, size = 'default' }) => {
    if (size === 'small') {
      return `
        ${theme.INPUT_14_REGULAR}
        padding: calc(0.5rem - 1px) calc(0.5rem - 1px);
      `;
    }

    if (size === 'default') {
      return `
        ${theme.INPUT_14_REGULAR}
        padding: calc(0.75rem - 1px) calc(0.625rem - 1px);
      `;
    }

    if (size === 'large') {
      return `
        ${theme.INPUT_16_REGULAR}
        padding: calc(0.875rem - 1px) calc(0.75rem - 1px) ;
      `;
    }
  }}
`;

export const Count = styled.div`
  ${({ theme }) => theme.CAPTION_12_REGULAR}
  color: ${({ theme }) => theme.GREY_5};
  display: block;
  margin-top: 0.25rem;
  text-align: right;
`;

const TextField = React.forwardRef(({ prefix, iconPrefix, showCount, ...props }, ref) => {
  return (
    <React.Fragment>
      {prefix}
      <TextInput ref={ref} {...props} prefix={iconPrefix} />
      {showCount && (
        <Count>
          {props.value?.length || 0}
          {props.maxLength != null && `/${props.maxLength}`}
        </Count>
      )}
    </React.Fragment>
  );
});

export default TextField;
