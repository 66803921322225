import { useState } from 'react';
import { useAmplifyAuth } from 'providers/AmplifyAuthProvider';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';

const ERROR_MESSAGE = {
  REQUIRE_RESET_PASSWORD: 'Password reset required for the user',
};

export const useCimLogin = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { loginWithEmail, tempUser } = useAmplifyAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [form] = Form.useForm();

  return {
    loading,
    errorMessage,
    form,
    onLogin: async payload => {
      try {
        setLoading(true);
        const result = await loginWithEmail(payload);
        setLoading(false);
        if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
          tempUser.current = result;
          history.push('/change-password');
          return;
        }

        history.replace('/');
      } catch (error) {
        setLoading(false);

        process.env.NODE_ENV !== 'production' && console.error(error.code);
        if (typeof error === 'object') {
          if (error.message === ERROR_MESSAGE.REQUIRE_RESET_PASSWORD) {
            history.push('/request-reset-password', {
              errorMessage: ERROR_MESSAGE.REQUIRE_RESET_PASSWORD,
            });
          } else {
            setErrorMessage(error.message);
          }
          return;
        }
        setErrorMessage(error);
      }
    },
  };
};
