export const INITIAL_FILTER_DATA = {
  keyword: '',
  roles: [],
  behavior: 'none',
  counter: 0, // for refetch
};

export const filterReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_KEYWORD_AND_ROLES':
      return {
        ...state,
        cursorId: '',
        behavior: 'none',
        keyword: action.payload.searchText,
        roles: action.payload.roleData.reduce((acc, cur, currentIndex) => {
          const comma = currentIndex === action.payload.roleData.length - 1 ? '' : ',';
          return `${acc + cur.value}${comma}`;
        }, ''),
      };
    case 'UPDATE_BEHAVIOR':
      return {
        ...state,
        behavior: action.payload,
        counter: state.counter + 1,
      };
    default:
      return state;
  }
};
