import React from 'react';
import { AntdPasswordInput, Container } from './_style';

const PasswordInput = React.forwardRef(({ size = 'middle', ...rest }) => {
  return (
    <Container size={size}>
      <AntdPasswordInput {...rest} />
    </Container>
  );
});

export default PasswordInput;
