/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { PrimaryButton, SecondaryButton, TertiaryButton } from 'design-system-components';
import { Row, Col } from 'antd';
import { useTranslation } from 'hooks/useI18n';
import { generateDate } from 'utils/generateDate';
import {
  CAMPAIGN_ROLES,
  CHATBOT_ROLES,
  FORM_DATA_MAPPING,
  ROLES_MAPPING,
} from 'constants/cim_member_editor';
import { useCimRole } from 'providers/CimRoleProvider';
import { ProductPermissionFormItem } from '../ProductPermissionFormItem';
import { UserAvatar } from '../UserAvatar';

import {
  FormContainer,
  ActionWrap,
  AboutGroup,
  TitleGroup,
  UserDetailLabel,
  UserDetailValue,
  ButtonWrap,
  RightSideGroup,
  ProductAccessWord,
  Spacing,
  RowContent,
  ColContent,
} from './_style';

export const MemberDetailContent = ({
  formData,
  isEditMode,
  onSwitchEditMode,
  memberInfo,
  onRoleChange,
  onCheckboxChange,
  onDeleteMember,
  onUpdate,
  isDisableCheckbox,
  isDisableSelect,
}) => {
  const t = useTranslation();
  const { role } = useCimRole();

  const _renderButton = () => {
    if (memberInfo?.roles.includes(ROLES_MAPPING.CIM_ADMIN)) {
      return null;
    }

    if (isEditMode) {
      return (
        <ActionWrap>
          <SecondaryButton onClick={onSwitchEditMode}>
            {t('member.detail.page.cancel.btn')}
          </SecondaryButton>
          <PrimaryButton htmlType="submit" onClick={onUpdate}>
            {t('member.detail.page.update.btn')}
          </PrimaryButton>
        </ActionWrap>
      );
    }

    return (
      <SecondaryButton data-testid="edit-btn" onClick={onSwitchEditMode}>
        {t('member.detail.page.edit.btn')}
      </SecondaryButton>
    );
  };

  return (
    <Row>
      <Col offset={4} span={16}>
        <FormContainer id="cim-member-editor">
          <Row justify="space-between">
            <Col>
              <UserAvatar username={memberInfo?.name} userEmail={memberInfo?.email} />
            </Col>
            <Col>{_renderButton()}</Col>
          </Row>
          <RowContent>
            <ColContent span={12}>
              {/* About */}
              <TitleGroup> {t('member.detail.page.about.label')}</TitleGroup>
              <AboutGroup>
                <Row wrap={false}>
                  <Col span={12}>
                    <UserDetailLabel> {t('member.detail.page.email.label')}</UserDetailLabel>
                  </Col>
                  <Col span={12}>
                    <UserDetailValue>{memberInfo?.email ?? '-'}</UserDetailValue>
                  </Col>
                </Row>
                <Row wrap={false}>
                  <Col span={12}>
                    <UserDetailLabel>{t('member.detail.page.telephone.label')}</UserDetailLabel>
                  </Col>
                  <Col span={12}>
                    <UserDetailValue>{memberInfo?.phone ?? '-'}</UserDetailValue>
                  </Col>
                </Row>
                <Row wrap={false}>
                  <Col span={12}>
                    <UserDetailLabel>{t('member.detail.page.join.date.label')}</UserDetailLabel>
                  </Col>
                  <Col span={12}>
                    <UserDetailValue>{generateDate(memberInfo?.createdAt) ?? '-'}</UserDetailValue>
                  </Col>
                </Row>
              </AboutGroup>
              <ButtonWrap onClick={onDeleteMember}>
                <TertiaryButton> {t('member.detail.page.remove.btn')}</TertiaryButton>
              </ButtonWrap>
            </ColContent>
            <Col span={12}>
              <RightSideGroup data-testid="test-member-detail-form-item-group">
                <TitleGroup>{t('member.detail.page.role.product.access.title')}</TitleGroup>
                <ProductAccessWord>
                  {t('member.detail.page.product.access.label')}
                </ProductAccessWord>
                <Spacing />
                <ProductPermissionFormItem
                  formItemCheckBoxName="chatbotCheckBox"
                  formSelectName="chatbot"
                  disabledCheckbox={isDisableCheckbox(role, 'chatbot')}
                  disabledSelect={isDisableSelect(role, 'chatbot')}
                  roles={CHATBOT_ROLES}
                  isCheck={formData?.chatbotCheckBox ?? false}
                  selectedRole={formData?.chatbotRole ?? undefined}
                  onRoleChange={data => onRoleChange(FORM_DATA_MAPPING.CHATBOT_ROLE, data)}
                  onCheckboxChange={data =>
                    onCheckboxChange(
                      FORM_DATA_MAPPING.CHATBOT_CHECKBOX,
                      FORM_DATA_MAPPING.CHATBOT_ROLE,
                      data,
                    )
                  }
                />
                <ProductPermissionFormItem
                  formItemCheckBoxName="campaignCheckbox"
                  formSelectName="campaign"
                  productName="Campaign"
                  disabledCheckbox={isDisableCheckbox(role, 'campaign')}
                  disabledSelect={isDisableSelect(role, 'campaign')}
                  roles={CAMPAIGN_ROLES}
                  isCheck={formData?.campaignCheckBox ?? false}
                  selectedRole={formData?.campaignRole ?? undefined}
                  onRoleChange={data => onRoleChange(FORM_DATA_MAPPING.CAMPAIGN_ROLE, data)}
                  onCheckboxChange={data =>
                    onCheckboxChange(
                      FORM_DATA_MAPPING.CAMPAIGN_CHECKBOX,
                      FORM_DATA_MAPPING.CAMPAIGN_ROLE,
                      data,
                    )
                  }
                />
              </RightSideGroup>
            </Col>
          </RowContent>
        </FormContainer>
      </Col>
    </Row>
  );
};
