import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Spin } from 'antd';
import { useTranslation } from 'hooks/useI18n';
import { LoadingWrap, LoadingCircle, DeleteModal } from '@amityco/diana-bot';
import { CHATBOT_ROLES } from 'constants/cim_member_editor';
import { Font16400G1 } from 'components/UtilitiesComponent';
import { MemberDetailContent } from './MemberDetailContent';
import { useCimMemberEditor } from './useCimMemberEditor';
import { Container, BreadcrumbContainer } from './_style';

const CimMemberEditor = ({ onUpdateTest }) => {
  const t = useTranslation();
  const {
    isEditMode,
    formData,
    memberInfo,
    memberInfoLoading,
    isDeleteModalOpen,
    isUpdating,
    isDeleting,
    onRoleChange,
    onDeleteMember,
    onConfirmDelete,
    onCancelDelete,
    onSwitchEditMode,
    onChatbotCheckBox,
    onFormChange,
    onUpdate,
    isDisableCheckbox,
    isDisableSelect,
  } = useCimMemberEditor();

  return (
    <Container>
      <BreadcrumbContainer>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/member">{t('cim.member.editor.page.breadcrumb.members')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t('cim.member.editor.page.breadcrumb.view.profile')}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbContainer>
      <Spin
        spinning={memberInfoLoading || isUpdating}
        indicator={
          <LoadingWrap>
            <LoadingCircle />
          </LoadingWrap>
        }
      >
        <MemberDetailContent
          formData={formData}
          isEditMode={isEditMode}
          memberInfo={memberInfo}
          roles={CHATBOT_ROLES}
          isDisableCheckbox={isDisableCheckbox}
          isDisableSelect={isDisableSelect}
          onDeleteMember={onDeleteMember}
          onSwitchEditMode={onSwitchEditMode}
          onFormChange={onFormChange}
          onCheckboxChange={onChatbotCheckBox}
          onRoleChange={onRoleChange}
          onUpdate={() => {
            const result = onUpdate();
            if (onUpdateTest) {
              onUpdateTest(result);
            }
          }}
        />
      </Spin>
      <DeleteModal
        destroyOnClose
        visible={isDeleteModalOpen}
        maskClosable={false}
        confirmBtnTxt={t('cim.member.editor.page.modal.confirm.delete.btn')}
        cancelBtnTxt={t('cim.member.editor.page.modal.cancel.delete.btn')}
        handleConfirm={onConfirmDelete}
        handleClose={onCancelDelete}
        isLoading={isDeleting}
        centered
      >
        <Font16400G1>{t('cim.member.editor.page.modal.delete.label')}</Font16400G1>
        <Font16400G1>
          {t('cim.member.editor.page.modal.delete.description', {
            name: memberInfo?.name ?? '',
          })}
        </Font16400G1>
      </DeleteModal>
    </Container>
  );
};

export default CimMemberEditor;
