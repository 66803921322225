import { Form } from 'antd';
import styled from 'styled-components';

const FormItem = styled(Form.Item)`
  margin-bottom: 1.25rem;

  &.align-right {
    text-align: right;
  }

  .ant-form-item-label {
    padding: 0 0 0.5rem;

    > label {
      width: 100%;

      ${({ theme }) => theme.BODY1_16_SEMIBOLD}
      height: auto;

      &.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
        &::before {
          display: none;
        }

        &::after {
          display: inline-block;
          color: ${({ theme }) => theme.RED};
          content: '*';

          .ant-form-item-hide-required-mark & {
            display: none;
          }
        }
      }
    }
  }

  .ant-form-item-explain,
  .ant-form-item-extra {
    ${({ theme }) => theme.CAPTION_12_REGULAR}
    padding-top: 0.25rem;
    min-height: 1rem;
    color: ${({ theme }) => theme.RED};
  }
`;

export default FormItem;
