import styled from 'styled-components';

export const Container = styled.div`
  height: ${({ height }) => height};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const Inner = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  span:first-child {
    margin-top: 8px;
  }
  span:last-child {
    margin-top: 4px;
  }
`;

export const IconFrame = styled.div`
  height: 96px;
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 72px;
  color: #bdbdbd;
`;
