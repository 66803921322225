import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const ColumnHeaderItemWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const CheckIcon = styled(FontAwesomeIcon)`
  color: #29cb72;
  font-size: 16px;
`;
export const BlockIcon = styled(FontAwesomeIcon)`
  color: #bdbdbd;
  font-size: 16px;
`;

export const NoCapital = styled.span`
  text-transform: initial;
`;

export const Capital = styled.span`
  text-transform: capitalize;
`;

export const UserNameGroup = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export const UserDetail = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;
