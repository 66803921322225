import styled from 'styled-components';

export const Container = styled.div`
  .ant-input-affix-wrapper {
    height: 48px !important;
  }
  .ant-col:nth-child(1) .ant-input-affix-wrapper {
    width: 290px;
  }
  .ant-col:nth-child(2) .ant-select-selector {
    width: 180px;
  }
`;
