import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ErrorLoading } from 'assets/images/error-loading.svg';
import { Button, GhostButton } from 'design-system-components';
import { useAmplifyAuth } from 'providers/AmplifyAuthProvider';

import { Result } from './style';

const InternalError = () => {
  const history = useHistory();
  const { user, logout } = useAmplifyAuth();

  const onLogout = async () => {
    await logout();
    return history.push(`/login`);
  };

  return (
    <Result
      icon={<ErrorLoading />}
      title="Sorry, something went wrong."
      extra={
        <Fragment>
          <a href="/">
            <Button size="large">Back Home</Button>
          </a>
          {user && (
            <GhostButton size="large" onClick={onLogout}>
              Back to login
            </GhostButton>
          )}
        </Fragment>
      }
    />
  );
};

export default InternalError;
