import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

export const useCurrency = (currency = 'USD', maximumFractionDigits = 2) => {
  const { formatNumber } = useIntl();

  return useCallback(
    (value, options = {}) =>
      formatNumber(value, {
        style: 'currency',
        currency,
        maximumFractionDigits,
        ...options,
      }),
    [currency, formatNumber, maximumFractionDigits],
  );
};

export function useDateTime() {
  const { formatDate } = useIntl();

  return useCallback(
    (value, options = {}) =>
      formatDate(value, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        ...options,
      }),
    [formatDate],
  );
}

export const useTranslation = () => {
  const { formatMessage } = useIntl();

  return useCallback(
    (id, value = {}) => (id ? formatMessage(defineMessages({ id }), value) : undefined),
    [formatMessage],
  );
};

export const useI18n = () => {
  const c = useCurrency();
  const d = useDateTime();
  const { formatNumber: n } = useIntl();
  const t = useTranslation();

  return { c, d, n, t };
};

export default useI18n;
