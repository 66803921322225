export * from './colors';
export * from './typography';

export const BREAKPOINT_MOBILE_VALUE_PX = 800;
export const BREAKPOINT_MOBILE = '@media only screen and (min-width: 50rem)';
export const BREAKPOINT_MOBILE_REVERSE = '@media only screen and (max-width: 50rem)';
export const BREAKPOINT_SM_REVERSE = `@media only screen and (max-width: 48rem)`;
export const BREAKPOINT_LG_REVERSE = `@media only screen and (max-width: 992px)`;
export const BREAKPOINT_XL_REVERSE = `@media only screen and (max-width: 1200px)`;
export const BREAKPOINT_XS_REVERSE = `@media only screen and (max-width: 576px)`;

export const PRIMARY = '#04be8c';
export const PRIMARY_HOVER = '#29c89d';
export const PRIMARY_DISABLED = '#c0dec5';
export const PRIMARY_FOCUS = '#03a277';

export const NEUTRAL_55 = '#818698';
export const NEUTRAL_GRAY_5 = '#9E9E9E';
export const NEUTRAL_GRAY_1 = '#212121';
export const NEUTRAL_GRAY_4 = '#757575';
export const GREY = '#e5e5e5';

export const SECONDARY = '#F5F5F5';

export const TERTIARY = '#212121';
export const TERTIARY_DESCRIPTION = '#BDBDBD';
export const TERTIARY_BORDER = '#212121';
export const TERTIARY_FOCUS = '#F5F5F5';
export const TERTIARY_DISABLED = '#E0E0E0';
export const GHOST_FOCUS = '#F5F5F5';
export const GHOST_DISABLED = '#9E9E9E';
export const ALTERNATE = '#000';
export const BASE = '#FFF';

export const BORDER = '#e4e6e7';
export const BOX_SHADOW = 'box-shadow: 0 8px 10px -6px rgba(0, 0, 0, 0.1);';

export const DESCRIPTION = '#616161';
