import styled from 'styled-components';

export default styled.div.attrs({ className: 'row' })`
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justify = 'initial' }) => justify};
  align-items: ${({ align = 'baseline' }) => align};
  margin-bottom: calc(-1 * var(--halfgap));

  & > .row {
    flex: 0 1 auto;
    flex-wrap: nowrap;
    margin-bottom: 0 !important;
  }

  & > :not(.row) {
    margin-bottom: var(--halfgap);
  }

  & > :not(:last-child) {
    margin-right: var(--halfgap);
  }
`;
